import axios from "axios"
import React, { useEffect, useState } from "react"
import { DeleteModal, Modal } from "../components/Modal"
import { Table } from "../components/Table"
import { VEHICLES_API } from "../constants/api"
import { getStorage } from "../utils/storage"
import toastMessage from "../utils/toastMessage"
import MachineryDetails from "../features/Machinery/MachineryDetails"
import useApp from "../hooks/useApp"

const MachinesPage = () => {
  const [machinery, setMachinery] = useState([])
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(true) //true by default
  const [viewModal, setViewModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [selected, setSelected] = useState(null)
  const [search, setSearch] = useState("")
  const { permissions } = useApp()

  useEffect(() => {
    getMachinery()
  }, [])

  useEffect(() => {
    const getData = setTimeout(() => {
      onSearch()
    }, 1000)
    return () => clearTimeout(getData)
  }, [search])

  const getMachinery = async (params) => {
    setLoading(true)
    const { token, id, permissions } = await getStorage()
    let perms = []
    if (permissions) {
      perms = permissions.map((perm) => perm.value)
    }
    let url = VEHICLES_API
    if (perms.includes("view-added-machinery")) {
      url += `/machines-by-user?userId=${id}`
    }
    if (perms.includes("view-machinery")) {
      url += "/machines"
    }
    const options = {
      url,
      method: "GET",
      headers: { authorization: `Bearer ${token}` },
      params: {
        ...params,
      },
    }

    axios(options)
      .then((res) => {
        setLoading(false)
        if (res.data) {
          const { pagination, results } = res.data

          setMachinery(results)
          setPagination(pagination)
        }
      })
      .catch((err) => {
        setLoading(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  } //GET ALL VEHICLES

  const handlePagination = (page) => {
    if (!page) return
    getMachinery({ pageNumber: page })
  }

  const handleView = async (item) => {
    await setSelected(item)
    setViewModal(true)
  }

  const handleDelete = (vehicle) => {
    setSelected(vehicle)
    setDeleteModal(true)
  }

  const handleCloseModal = () => {
    setDeleteModal(false)
    setViewModal(false)
    setSelected(null)
  }

  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    if (value === "") {
      return getMachinery()
    }
  }

  function onSearch() {
    if (!search || search === "") return
    getMachinery({ search: search.toUpperCase().trim() })
  }

  const onDelete = async () => {
    const { token } = await getStorage()
    setDeleting(true)

    const options = {
      method: "DELETE",
      url: VEHICLES_API + "/machinery/" + selected.id,
      headers: {
        authorization: "Bearer " + token,
      },
    }

    axios(options)
      .then((res) => {
        setDeleting(false)
        if (res.status === 200) {
          toastMessage("success", "Machinery deleted successfully")
          handleCloseModal()
          getMachinery()
        }
      })
      .catch((err) => {
        setDeleting(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  const headers = [
    { title: "Machinery Type", key: "type" },
    { title: "Make", key: "make" },
    { title: "Model", key: "model" },
    // { title: "Year", key: "year" },
    // { title: "Engine Capacity", key: "capacity" },
    { title: "Client FirstName", key: "firstName" },
    { title: "Client LastName", key: "lastName" },
    { title: "Company Name", key: "companyName" },
    { title: "Client Phone", key: "phone" },
    {
      title: "Date of Registration",
      key: "createdAt",
      isMoment: true,
      formatTime: "lll",
    },
  ]
  if (permissions && permissions.includes("manage-machinery")) {
    headers.push({ title: "Action", key: "action" })
  }
  return (
    <>
      <div className="card">
        <div className="card-body">
          <Table
            headers={headers}
            data={machinery || []}
            search_text={search}
            handleSearch={handleSearch}
            isLoading={loading}
            addButtonText="Add Vehicle"
            placeholder={"Search by type"}
            pagination={pagination || {}}
            handlePagination={handlePagination}
            // rowPress={handleView}
            actions={[
              {
                name: "View",
                onPress: (row) => handleView(row),
              },
              {
                name: "Delete",
                onPress: (row) => handleDelete(row),
              },
            ]}
          />
        </div>
      </div>
      <Modal
        show={viewModal}
        title={"Machinery Management"} //+ selected ? `: ${selected.firstName} ${selected.lastName}`: ''}
        handleClose={handleCloseModal}
        // size='lg'
      >
        <MachineryDetails
          selectedMachinery={selected}
          setSelected={setSelected}
          getMachinery={getMachinery}
          handleClose={handleCloseModal}
        />
      </Modal>

      <Modal
        show={deleteModal}
        title={`Delete Machinery`}
        handleClose={handleCloseModal}
      >
        <DeleteModal
          name={selected && selected.type}
          handleClose={handleCloseModal}
          isSubmitting={deleting}
          onDelete={onDelete}
        />
      </Modal>
    </>
  )
}

export default MachinesPage
