import {Tabs} from "../../components/Tabs";
import Permissions from "./Permissions";
import {useState} from "react";
import AddPermission from "./AddPermission";

export default function PermissionsManagement({ user, handleClose}){
    const [active, setActive] = useState()
    const [passedUser] = useState(user)

    const options = [
        {
            title:"Permissions",
            data: <Permissions
                    user={passedUser}
                    handleClose={handleClose}
                    setActive={setActive}
                    active={active}
                />
        },
        {
            title: "Add",
            data: <AddPermission
                    user={user}
                    handleClose={handleClose}
                    setActive={setActive}
                />
        }
    ]
    return <Tabs options={options} activeTab={active}/>
}