import axios from 'axios'
import React, {useState, useEffect} from 'react'
import {Button} from '../../components/Button'
import {Input} from '../../components/Input'
import {VEHICLES_API} from '../../constants/api'
import {getStorage} from '../../utils/storage'
import toastMessage from '../../utils/toastMessage'
import useApp from "../../hooks/useApp";

const Vehicle = (props) => {
    const {
        client,
        selectedVehicle,
        handleClose,
        setEditVehicle,
        changeTab,
        setTrigger,
        disabled
    } = props

    const defualtVehicle = {
        registration: "",
        make: "",
        model: "",
        year: "",
        capacity: '',
    }

    const [vehicle, setVehicle] = useState(defualtVehicle)
    const [error, setError] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const {permissions} = useApp()

    useEffect(() => {
        if (selectedVehicle) {
            setVehicle(selectedVehicle)
        }
    }, [selectedVehicle])

    const validateVehicle = async () => {
        const {
            registration,
            make,
            model,
            capacity,
            year
        } = vehicle

        let err = {}, isValid = true

        if (!registration || registration === "") {
            err.registration = "Vehicle Registration must be provided"
            isValid = false
        }
        if (!make || make === "") {
            err.make = "Vehicle Make must be provided"
            isValid = false
        }
        if (!model || model === "") {
            err.model = "Vehicle Model must be provided"
            isValid = false
        }
        if (!capacity || capacity === "") {
            err.capacity = "Engine Capacity must be provided"
            isValid = false
        }
        if (!year || year === "") {
            err.year = "Vehicle Year of manufacture must be provided"
            isValid = false
        }

        return isValid
    }

    const handleSubmit = async () => {
        let valid = await validateVehicle()
        if (!valid) return


        setSubmitting(true)

        const {token} = await getStorage()
        let method = selectedVehicle ? "PUT" : "POST"
        let path = selectedVehicle ? `/vehicle/${selectedVehicle.id}` : `/vehicle`

        const {
            registration,
            make,
            model,
            capacity,
            year
        } = vehicle

        let params = {}
        if (!selectedVehicle) {
            params.clientId = client.id
        }

        const options = {
            method,
            url: VEHICLES_API + path,
            headers: {
                authorization: "Bearer " + token,
            },
            data: {
                ...params,
                registration: registration.toUpperCase().trim().replace(" "),
                make,
                model,
                capacity,
                year
            }
        };

        axios(options)
            .then(res => {
                setSubmitting(false)
                if (res.status === 201) {
                    setTrigger(prev => !prev)
                    setVehicle(defualtVehicle)
                    changeTab("client vehicles")
                    return toastMessage(
                        "success",
                        "Vehicle added successfully."
                    )
                }

                if (res.status === 200) {
                    setEditVehicle(null)
                    setVehicle(defualtVehicle)
                    changeTab("client vehicles")
                    return toastMessage(
                        "success",
                        "Vehicle updated successfully."
                    )
                }
            })
            .catch((err) => {
                setSubmitting(false)
                if (!err.response || !(err.response && err.response.data)) {
                    return toastMessage(
                        "error",
                        "Connection failed. This could either be due to network failure or the resource is currently unavailable"
                    )
                }
                toastMessage("error", err.response.data.message)
            })
    }

    const handleInput = (e) => {
        const {name, value} = e.target

        setVehicle(prev => ({
            ...prev,
            [name]: value
        }))
    }


    if (!(permissions && permissions.includes("manage-vehicles"))) {
        return null
    }
    return (
        <>
            <div className="modal-body">
                <div className="row">
                    <Input
                        name="registration"
                        error={error.registration}
                        onChange={handleInput}
                        type="text"
                        required
                        disabled={disabled}
                        value={vehicle.registration}
                        label="Vehicle Registration:"
                    />

                    <div className="col-md-6">
                        <Input
                            name="make"
                            error={error.make}
                            onChange={handleInput}
                            type="text"
                            required
                            disabled={disabled}
                            value={vehicle.make}
                            label="Vehicle Make:"
                        />
                    </div>

                    <div className="col-md-6">
                        <Input
                            name="model"
                            error={error.model}
                            onChange={handleInput}
                            type="text"
                            required
                            disabled={disabled}
                            value={vehicle.model}
                            label="Vehicle Model:"
                        />
                    </div>


                    <div className="col-md-6">
                        <Input
                            name="year"
                            error={error.year}
                            onChange={handleInput}
                            type="text"
                            required
                            disabled={disabled}
                            value={vehicle.year}
                            label="Year of Manufacture:"
                        />
                    </div>

                    <div className="col-md-6">
                        <Input
                            name="capacity"
                            error={error.capacity}
                            onChange={handleInput}
                            type="text"
                            required
                            disabled={disabled}
                            value={vehicle.capacity}
                            label="Engine Capacity:"
                        />
                    </div>

                </div>
            </div>
            <div className="modal-footer d-flex gap-3">
                <Button
                    text="Cancel"
                    className="btn-secondary"
                    onPress={() => handleClose(false)}
                />
                <Button
                    isSubmitting={submitting}
                    onPress={handleSubmit}
                    disabled={disabled}
                    text={selectedVehicle ? "Update" : "Submit"}
                    className="btn-primary"
                />
            </div>
        </>
    )
}

export default Vehicle