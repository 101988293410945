import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Button } from '../../components/Button'
import { DeleteModal, Modal } from '../../components/Modal'
import { Table } from '../../components/Table'
import { VEHICLES_API } from '../../constants/api'
import { getStorage } from '../../utils/storage'
import toastMessage from '../../utils/toastMessage'

const ViewMachinery = (props) => {
  const { 
    handleClose, 
    setEditMachinery, 
    selectedMachinery, 
    changeTab , 
    client, 
    triggerGetMachinery
  } = props

  const [machinery, setMachinery] = useState([])
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(true)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [selected, setSelected] = useState(null)
  const [search, setSearch] = useState("")


  useEffect(()=>{
    getMachinery()
  }, [selectedMachinery, triggerGetMachinery])

  useEffect(()=>{
    const getData = setTimeout(()=>{
      onSearch()
    }, 1000)
    return () => clearTimeout(getData)
  }, [search])


  async function getMachinery(params){
    const { token } = await getStorage()

    setLoading(true)

    const options = {
      method: "GET",
      url: VEHICLES_API + "/machines/"+client.id,
      headers: {
        authorization: "Bearer " + token,
      },
      params: {
        ...params
      }
    };

    axios(options)
    .then(res=>{
      setLoading(false)
      if(res.data){
        const { pagination, results } = res.data

        setMachinery(results)
        setPagination(pagination)
      }
    })
    .catch((err)=>{
      setLoading(false)
      if(!err.response){
        return toastMessage(
          "error",
          "Connection failed. This could either be due to network failure or the resource is currently unavailable"
        )
      }
      toastMessage("error", err.response.data.message)
    })
  }


  const handleEdit=async(vehicle)=>{
    await setEditMachinery(vehicle)
    changeTab("add machinery")
  }

  const handleAdd=()=>{
    changeTab("add machinery")
  }

  const handleDelete=(vehicle)=>{
    setSelected(vehicle)
    setDeleteModal(true)
  }

  const handlePagination = (page)=>{
    if(!page) return
    getMachinery({ pageNumber: page })

  }

  const handleCloseModal=()=>{
    setDeleteModal(false)
    setSelected(null)
  }

  const onDelete=async()=>{
    const { token } = await getStorage() 
    setDeleting(true)

    const options = {
      method: "DELETE",
      url: VEHICLES_API + "/machinery/"+selected.id,
      headers: {
        authorization: "Bearer " + token,
      }
    };

    axios(options)
    .then(res=>{
      setDeleting(false)
      if(res.status === 200){
        toastMessage("success", "Machinery deleted successfully")
        handleCloseModal()
        getMachinery()
      }

    })
    .catch((err)=>{
      setDeleting(false)
      if(!err.response){
        return toastMessage(
          "error",
          "Connection failed. This could either be due to network failure or the resource is currently unavailable"
        )
      }
      toastMessage("error", err.response.data.message)
    })
    
  }

  const handleSearch =(e)=>{
    const { value } = e.target
    setSearch(value)
    if(value === ""){
      return getMachinery()
    }
  }

  function onSearch(){
    if(!search || search === "") return
    getMachinery({ search: search.toUpperCase().trim()})
  }

  
  const headers = [
    { title: "Machine Type", key: "type" },
    { title: "Make", key: "make" },
    { title: "Model", key: "model" },
    { title: "Year", key: "year" },
    { title: "Engine Capacity", key: "capacity" },
    { title: "Date of Registration", key: "createdAt", isMoment: true, formatTime: "lll" },
    { title: "Action", key: "action"}
  ]

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Table
            headers={headers}
            data={machinery}
            showAdd
            search_text={search}
            handleSearch={handleSearch}
            isLoading={loading}
            addButtonText="Add Machinery"
            placeholder={"Search by type"}
            handleAddPressed={handleAdd}
            pagination={pagination}
            handlePagination={handlePagination}
            actions={[
              {
               name: "Edit",
               onPress: (row)=> handleEdit(row),
              },
              {
                name: "Delete",
                onPress: (row)=> handleDelete(row),
              },
            ]}
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button  
          text="Cancel" 
          className="btn-secondary" 
          onPress={()=> handleClose(false)}
        />
      </div>
      <Modal
        show={deleteModal}
        title={`Delete Vehicle`}
        handleClose={handleCloseModal}
      >
        <DeleteModal
          name={selected&&(selected.type)}
          handleClose={handleCloseModal}
          isSubmitting={deleting}
          onDelete={onDelete}
        />
      </Modal>
    </div>
  )
}

export default ViewMachinery