import {Tabs} from "../components/Tabs";
import {PartsStock} from "../features/PartsStock";
import {useState} from "react";
import {ProductsPage} from "../features/Product";

export default function PartsStockPage(){
    const [active, setActive] = useState(false)

    const options = [
        {
            title: "All Stock",
            data: <PartsStock
                active={active}
                setActive={setActive}
            />
        },
        {
            title: "Available Stock",
            data: <PartsStock
                type="/in-stock"
                active={active}
                setActive={setActive}
            />
        },
        {
            title: "Out of Stock",
            data:<PartsStock
                type="/out-of-stock"
                active={active}
                setActive={setActive}
            />
        }
    ]

    // return <PartsStock />
    return <Tabs options={options} />
}