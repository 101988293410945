import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { LoadingSpinner } from "../LoadingSpinner";

const Navbar = ({
  user,
  onToggle,
  sidebarSize,
  onSignMeOut,
  title,
}) => {

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="navbar-brand" to="#">
            <div className={`toggleBtn ${sidebarSize}`} onClick={onToggle}>
              <i className={"bx bx-menu"}></i>
            </div>
            <h1>{title}</h1>
          </div>
          <div className="navbar-content">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              
              <li className="nav-item">
                <Link className="nv-i" to="/home/settings">
                  <i className="bx bxs-cog"></i>
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {user.firstName ? user.firstName + " " + user.lastName : "-"}
                </Link>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={onSignMeOut}
                    >
                      Logout
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="d-flex"></div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
