import { Tabs, Tab } from 'react-bootstrap'
import './style.css'

const ControlledTabs = props => {
	// const [key, setKey] = useState(
	//   props.options.length > 0 ? props.options[0].title.toLowerCase() : undefined
	// );UncontrolledTabs

	// useEffect(() => {
	//   if (props.activeTab) {
	//     setKey(props.activeTab);
	//   }
	// });

	return (
		<>
			<Tabs
				id="controlled-tab-example"
				activeKey={props.key}
				onSelect={props.onSelect}
				className="tab mb-2 flex-nowrap"
				style={props.style}
			>
				{props.options.map((item, i) => {
					return (
						<Tab key={i} eventKey={item.title} title={item.title}>
							{props.activeKey === item.title && item.data}
						</Tab>
					)
				})}
			</Tabs>
		</>
	)
}

export default ControlledTabs
