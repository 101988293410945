import React, {useEffect} from "react";
import PropTypes from "prop-types";
import "./styles.css";
import useApp from "../../hooks/useApp";

const Container = ({menus = [], permissions=[], children}) => {
    const {setMenus, setPermissions} = useApp()
    useEffect(() => {
        setMenus(menus)
        setPermissions(permissions)
    }, []);
    return (
        <div className="app_container">
            <div className="container-fluid p-0">{children}</div>
        </div>
    );
}

Container.propTypes = {
    children: PropTypes.any,
};

export default Container;
