import axios from "axios"
import React, { useEffect, useState } from "react"
import { Button } from "../../components/Button"
import { Table } from "../../components/Table"
import { PRODUCTS_API } from "../../constants/api"
import { getStorage } from "../../utils/storage"
import toastMessage from "../../utils/toastMessage"

const Products = ({ invoice, handleClose }) => {
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getData()
  }, [])

  const getData = async (params = {}) => {
    const { token } = await getStorage()
    setLoading(true)

    const options = {
      method: "GET",
      url: PRODUCTS_API + "/purchase/" + invoice.id,
      headers: {
        authorization: "Bearer " + token,
      },
      params: {
        ...params,
      },
    }

    axios(options)
      .then(async(res) => {
        setLoading(false)
        if (res.data) {
          const arr = await res.data.map(item=>{
            if(item.ART_ARTICLE_NR){
              return {
                ...item,
                name: item.ART_ARTICLE_NR,
                description: item.ART_SUP_BRAND,
                stock: item.PRI_STOCK_QUANTITY
              }
            }
            return item
          })
          setData(arr)
        }
      })
      .catch((err) => {
        setLoading(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  const headers = [
    { title: "Name/Article", key: "name" },
    // { title: "Image", key: "image", isLink: true },
    // { title: "Item", key: "categoryName" },
    { title: "Price", key: "price" },
    { title: "Quantity", key: "quantity" },
    { title: "Stock", key: "stock" },
    { title: "Description/Brand", key: "description" },
    { title: "Additional Info", key: "additionalInfo" },
    // { title: "Action", key: "action"}
  ]
  return (
    <>
      <div className="card-body">
        <Table
          pagination={pagination}
          isLoading={loading}
          data={data}
          headers={headers}
        />
      </div>
      <div className="card-footer">
        <Button
          className="btn btn-secondary"
          text="Close"
          onPress={() => handleClose(false)}
        />
      </div>
    </>
  )
}

export default Products
