import React from "react";
import { Link } from "react-router-dom";
import Select from "./Select";
import { countries } from "../../constants/countries";
import "./styles.css";

class Input extends React.Component {
  state = {
    showPassword: false,
  };

  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  render() {
    const { showPassword } = this.state;
    const props = this.props;
    return (
      <div className="form-group has-error" style={props.inputContainerStyle}>
        {props.label && (
          <div className={`input-label ${props.hidden && ' hidden '}`}>
            <label>
              {props.label}{" "}
              {props.required && <span className="required">*</span>}
            </label>
            {props.isForgotPassword && (
              <Link to="/forgot-password">Forgot Password?</Link>
            )}
          </div>
        )}

        <div className="input-content">
          {props.isPhone && (
            <div className="countries no-border-bottom">
              <Select
                value={props.countryValue}
                onChange={props.countryOnchange}
                error={props.countryError}
                name={props.countryName}
                options={countries}
                isDisabled={props.disabled}
              />
            </div>
          )}
          {props.type === "places" && { ...props }}
          <input
            type={showPassword ? "text" : props.type}
            className={`form-control ${props.className} ${
              props.error && "is-invalid "
            } ${
              props.borderBottom ? "input-border-bottom" : "input-all-border"
            }`}
            value={props.value}
            accept={props.accept}
            onChange={props.onChange}
            maxLength={props.maxLength}
            minLength={props.minLength}
            autoComplete={props.autoComplete}
            name={props.name}
            onClick={props.onClick}
            placeholder={props.placeholder}
            required={props.required}
            disabled={props.disabled}
          />
          {props.showRightContent && (
            <div
              className="right-box"
              onClick={this.handleShowPassword.bind(this)}
            >
              {props.type && props.type === "password" && (
                <i
                  className={`${
                    this.state.showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                  }`}
                />
              )}
              {props.hasResend && (
                <Link
                  onClick={props.handleResend}
                  to="#"
                  className={props.isResending && "link-disabled"}
                >
                  {props.isResending ? "Resending..." : "Resend"}
                </Link>
              )}
            </div>
          )}
          {props.iconRight && (
            <div className="iconRight">
              <i className={props.iconRight}></i>
            </div>
          )}
        </div>
        {props.error && <div className="text-danger">{props.error}</div>}
      </div>
    );
  }
}

export default Input;
