import {Button} from "../../components/Button";
import {useState} from "react";
import {PRODUCTS_API} from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import {getStorage} from "../../utils/storage";


export default function DeleteBrand({ brand, handleClose, getData }){
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e) {
        e && e.preventDefault()
        if(!brand||!brand.id) return
        setLoading(true)
        const { token } = await getStorage()
        const options = {
          method: "DELETE",
          url: `${PRODUCTS_API}/brand/${brand.id}`,
          headers: {
            authorization: "Bearer " + token,
          }
        };

        axios(options)
            .then(res=>{
              setLoading(false)
              if(res.data){
                toastMessage("success", "Brand deleted successfully")
                  handleClose()
                  getData()
              }
            })
            .catch((err)=>{
              setLoading(false)
              if(!err.response){
                return toastMessage(
                  "error",
                  "Connection failed. This could either be due to network failure or the resource is currently unavailable"
                )
              }
              toastMessage("error", err.response.data.message)
            })
    }

    return (
        <div className="card">
            <div className="card-body d-flex flex-column gap-3 align-items-center justify-content-center">
                <i style={{ fontSize: "3em", color: "red"}} className='bx bx-error'></i>
                <p>Are you sure you want to delete {brand&&brand.name} brand?</p>
            </div>
             <div className="card-footer d-flex justify-content-end gap-3">
                <Button className={"btn-secondary"} onPress={handleClose} text={"Cancel"} />
                <Button className={"btn-danger"} onPress={handleSubmit} text={"Delete"} isSubmitting={loading} />
            </div>
        </div>
    )

}