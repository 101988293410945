import React, { useEffect, useState } from "react"
import { Table } from "../../components/Table"
import { Button } from "../../components/Button"
import { PRODUCTS_API } from "../../constants/api"
import { getStorage } from "../../utils/storage"
import axios from "axios"
import { Spinner } from "react-bootstrap"
import toastMessage from "../../utils/toastMessage"
import useApp from "../../hooks/useApp"
import { useHistory } from "react-router-dom"

const ViewUploads = () => {
	const { uploads } = useApp()
	const [data, setData] = useState(uploads)
	const [submitting, setSubmitting] = useState(false)
	const [disabled, setDisabled] = useState(false)
	const history = useHistory()

	useEffect(() => {
		let arr = uploads.map((item) => {
			return {
				...item,
				status: <span className="badge-not-submitted">Not Submitted</span>,
			}
		})
		setData(arr)
	}, [])

	const handleClose = () => {
		history.push("../products")
	}

	const submit = async () => {
		setSubmitting(true)
		for (let i = 0; i < data.length; i++) {
			let newUpload = { ...data[i] }
			newUpload.status = (
				<span className="badge-not-submitted">
					<Spinner size="sm" animation="border" variant="success" />
				</span>
			)
			let newData = [...data]
			newData[i] = newUpload
			setData(newData)
			let details = await getDetails(
				newUpload["Category"] && newUpload["Category"].trim(),
				newUpload["Sub Category"] && newUpload["Sub Category"].trim(),
				newUpload["Product Type"] && newUpload["Product Type"].trim()
			)
			console.log("Details: ", details)
			if (details) {
				let { err, res } = await saveProduct(data[i], details)
				if (res) {
					// getProducts()
					let stock = await saveStock(data[i], res)
					if (stock) {
						toastMessage("success", "Product(s) uploaded successfully.")
						newUpload.status = (
							<span className="badge-success">Upload Success</span>
						)
						newData[i] = newUpload
						setData(newData)
					} else {
						newUpload.status = (
							<span className="badge-error">Error. Failed Upload</span>
						)
						newData[i] = newUpload
						setData(newData)
					}
				} else if (err.response) {
					newUpload.status = (
						<span className="badge-error">
							Error. {err.response.data.message}
						</span>
					)
					newData[i] = newUpload
					setData(newData)
				} else {
					//set error uploading
					newUpload.status = (
						<span className="badge-error">Error. Failed Upload</span>
					)
					newData[i] = newUpload
					setData(newData)
				}
			} else {
				//set error uploading
				newUpload.status = (
					<span className="badge-error">Error. Failed Upload</span>
				)
				newData[i] = newUpload
				setData(newData)
			}
		}
		setDisabled(true)
		setSubmitting(false)
	}

	async function saveProduct(product, type) {
		console.log("Save this product: ", { product, type })
		if (
			!product["Product Name"] ||
			!product["Description(Min 100words)"] ||
			!type.product_type_id ||
			!type.sub_category_id
		) {
			return {}
		}
		const { token } = await getStorage()
		const form = new FormData()
		form.append("name", product["Product Name"])
		form.append("type_id", type.product_type_id)
		form.append("subCategoryId", type.sub_category_id)
		form.append("description", product["Description(Min 100words)"])
		if (product["Additional Info(optional)"]) {
			form.append("additionalInfo", product["Additional Info(optional)"])
		}
		if (product["Tag(optional)"]) {
			form.append("tag", product["Tag(optional)"])
		}
		const options = {
			url: `${PRODUCTS_API}/products`,
			method: "POST",
			headers: {
				authorization: "Bearer " + token,
			},
			data: form,
		}
		return await axios(options)
			.then((res) => {
				if (res.data) {
					return { res: res.data }
				} else {
					return {}
				}
			})
			.catch((err) => {
				return { err }
				// toastMessage("error", err.response.data.message)
			})
	}

	async function saveStock(stock, product) {
		console.log("Save this product: ", { product, stock })
		if (!stock["Price"] || !stock["Quantity"] || !product.id) return null
		const { token } = await getStorage()
		const options = {
			url: `${PRODUCTS_API}/stock`,
			method: "POST",
			headers: { authorization: "Bearer " + token },
			data: {
				product_id: product.id,
				price: stock["Price"],
				slashed_price: stock["Slashed Price(Optional)"] || "",
				quantity: stock["Quantity"],
			},
		}
		return await axios(options)
			.then((res) => {
				setSubmitting(false)
				if (res.data) {
					return res
				}
				return null
			})
			.catch((err) => {
				return null
			})
	}

	async function getDetails(category, subcategory, type) {
		if (!category || !subcategory || !type) return null
		const { token } = await getStorage()
		const options = {
			url: PRODUCTS_API + "/product/types",
			method: "GET",
			params: { category, subcategory, type },
			headers: { authorization: "Bearer " + token },
		}
		return await axios(options)
			.then(async (res) => {
				if (res.data) {
					return await res.data
				}
				return null
			})
			.catch((err) => {
				return null
			})
	}

	const headers = [
		{ title: "Upload Status", key: "status", isBadge: true },
		{ title: "Product Name", key: "Product Name" },
		{ title: "Category", key: "Category" },
		{ title: "Sub Category", key: "Sub Category" },
		{ title: "Product Type", key: "Product Type" },
		{ title: "Tag(optional)", key: "Tag(optional)" },
		{ title: "Description(Min 100words)", key: "Description(Min 100words)" },
		{ title: "Additional Info(optional)", key: "Additional Info(optional)" },
		{ title: "Price", key: "Price" },
		{ title: "Slashed Price(Optional)", key: "Slashed Price(Optional)" },
		{ title: "Quantity", key: "Quantity" },
	]

	return (
		<div className="card">
			<div className="card-body">
				<Table
					headers={headers}
					data={data}
					pagination={{}}
					showAdd
					addButtonText="Back to Products"
					addIcon="bx bx-left-arrow-alt"
					handleAddPressed={handleClose}
				/>
			</div>
			<div className="card-footer d-flex gap-4 justify-content-end">
				<Button text="Close" className="btn-default" onPress={handleClose} />
				<Button
					text="Submit"
					disabled={disabled}
					isSubmitting={submitting}
					className="btn-primary"
					onPress={submit}
				/>
			</div>
		</div>
	)
}

export default ViewUploads
