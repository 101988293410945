import React, { useState, useEffect } from 'react'
import { Button } from '../../components/Button'
import { Input, Select } from '../../components/Input'
import { CLIENTS_API, USERS_API } from '../../constants/api'
import { countries } from '../../constants/countries'
import axios from 'axios'
import { getStorage } from '../../utils/storage'
import toastMessage from '../../utils/toastMessage'
import USER_ROLES from "../../constants/userRoles"

const User = (props) => {
  const { edit, selected, handleClose, getUsers } = props

  const defualtUser = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: USER_ROLES[0].value,
    password: "",
    selected_country: countries[0]
  }

  const [user, setUser] = useState({...defualtUser})

  const [error, setError] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [disabled, setDisabled] = useState(true)

  useEffect(()=>{
    if(selected){
      // let role = { label: selected.role, value: selected.role }
      // selected.role = role
      setUser(prev=>({
        ...prev,
        ...selected
      }))
    }
    if(edit || !selected){
      setDisabled(false)
    }

    return ()=>{
      setUser(defualtUser)
    }

  }, []) 

  const cancel = ()=>{
    handleClose(false)
  }

  const validateUser = async ()=>{
    const {
      firstName,
      lastName,
      email,
      role,
      password,
      phone
    } = user

    const err = {}

    let isValid = true

    if(!firstName || firstName === ""){
      err.firstName = "First Name is required"
      isValid = false

    }

    if(!lastName || lastName === ""){
      err.lastName = "Last Name is required"
      isValid = false

    }

    if(!email || email === ""){
      err.email = "Email address is required"
      isValid = false

    }

    if(!edit){
      if(!role || role === ""){
        err.role = "User Role is required"
        isValid = false
  
      }
      // if(!password || password === ""){
      //   err.password = "User Password is required"
      //   isValid = false
      //
      // }
      // if(password&&password.length < 6){
      //   err.password = "Password MUST be atleast 6 digits long"
      //   isValid=false
      // }
    }


    if(!phone || phone === ""){
      err.phone = "Phone Number is required"
      isValid = false

    }

    if(phone&&phone.length < 9){
      err.phone = "A valid phone number is required"
      isValid = false
    }

    setError(err)

    return isValid
  }

  const handleSubmit = async()=>{

    const valid = await validateUser()
    if(!valid) return

    setSubmitting(true)

    const { token } = await getStorage()
    let method = edit&&edit ? "PUT" : "POST"
    let path = edit&&edit ? `/user/${selected&&selected.id}`: "/user"

    const {
      firstName,
      lastName,
      email,
      role,
      password,
      phone,
      selected_country
    } = user

    let phoneNumber = phone

    if(phoneNumber.length < 12){
      phoneNumber = selected_country&&selected_country.value+phone
    }

    if(phone.length > 9 && phone[0] == "0"){
      phoneNumber = selected_country.value + phone.slice(1, 10)        
    }

    let data = {
      firstName,
      lastName,
      email,
      // password,
      role,
      phone: phoneNumber
    }

    if(edit){
      data = {
        firstName,
        lastName,
        email,
        role,
        phone: phoneNumber
      }
    }

    const options = {
      method,
      url: USERS_API + path,
      headers: {
        authorization: "Bearer " + token,
      },
      data
    };

    axios(options)
    .then(res=>{
      setSubmitting(false)
      if(res.status === 201){
        getUsers()
        cancel()
        return toastMessage(
          "success",
          "User added successfully."
        )
      }

      if(res.status === 200){
        getUsers()
        cancel()
        return toastMessage(
          "success",
          "User updated successfully."
        )

      }
    })
    .catch((err)=>{
      setSubmitting(false)
      console.log("Added error: ", err)

      if(!err.response || !(err.response&&err.response.data)){
        return toastMessage(
          "error",
          "Connection failed. This could either be due to network failure or the resource is currently unavailable"
        )
      }
      toastMessage("error", err.response.data.message)
    })
  }

  const handleInput = (e)=>{
    const { name, value } = e.target

    if(error[name]){
      delete error[name]
    }
    setUser(prev=>({
        ...prev,
        [name]: value
    }))
  }

  const handleSelect=( { value } , { name })=>{
    if(error[name]){
      delete error[name]
    }
    setUser(prev=>({
        ...prev,
        [name]: value
    }))
  }

  return (
    <div>
      <div className="modal-body">
        <Input
          name="firstName"
          error={error.firstName}
          onChange={handleInput}
          type="text"
          required
          disabled={disabled}
          value={user.firstName}
          label="First Name:"
        />
        <Input
          name="lastName"
          error={error.lastName}
          onChange={handleInput}
          disabled={disabled}
          type="text"
          required
          value={user.lastName}
          label="Last Name:"
        />
        {/*<Select*/}
        {/*  name="role"*/}
        {/*  error={error.role}*/}
        {/*  onChange={handleSelect}*/}
        {/*  options={USER_ROLES}*/}
        {/*  type="text"*/}
        {/*  required*/}
        {/*  isDisabled={disabled}*/}
        {/*  value={user.role}*/}
        {/*  label="Role:"*/}
        {/*/>*/}
        
        <Input
          label="Phone Number:"
          name="phone"
          value={user.phone}
          onChange={handleInput}
          disabled={disabled}
          error={error.phone}
          required
          isPhone
          // type={'number'}
          maxLength={10}
          minLength={9}
          countries={countries}
          countryValue={user.selected_country}
          countryOnchange={(e) =>
            handleSelect("selected_country", e)
          }
          type="telephone"
        />
        <Input
          name="email"
          error={error.email}
          onChange={handleInput}
          type="email"
          required
          autoComplete="off"
          disabled={disabled}
          value={user.email}
          label="Email:"
        />

        
        {/*<Input*/}
        {/*  name="password"*/}
        {/*  error={error.password}*/}
        {/*  onChange={handleInput}*/}
        {/*  type="password"*/}
        {/*  required*/}
        {/*  autoComplete="off"*/}
        {/*  inputContainerStyle={(disabled || edit) ? { display: "none"}: {}}*/}
        {/*  disabled={disabled}*/}
        {/*  value={user.password}*/}
        {/*  label="Password:"*/}
        {/*/>*/}
      </div>
      <div className="modal-footer d-flex gap-3">
        <Button  
          text="Cancel" 
          className="btn-secondary" 
          onPress={cancel}
          />
        <Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          text={edit ? "Update": "Submit"}
          disabled={disabled}
          className="btn-primary"
        />
      </div>

    </div>
  )
}

export default User
