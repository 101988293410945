import React, {useState} from 'react'
import {Tabs} from "../../components/Tabs"
import Machinery from '../Machinery/Machinery'
import ViewMachinery from '../Machinery/ViewMachinery'
import Vehicle from '../Vehicles/Vehicle'
import ViewVehicles from '../Vehicles/ViewVehicles'
import Client from './Client'
import permissions from "../../constants/permissions";
import useApp from "../../hooks/useApp";

const ViewClient = (props) => {
    const [active, setActive] = useState(null)
    const [editVehicle, setEditVehicle] = useState(null)
    const [editMachinery, setEditMachinery] = useState(null)
    const [triggerGetVehicles, setTrigger] = useState(false)
    const [triggerGetMachinery, setTriggerMachinery] = useState(false)
    const {client, edit, getClients, handleClose, setSelected} = props
    const {permissions} = useApp()

    const changeActiveTab = async (tab) => {
        await setActive(tab)
        setActive(null)
    }

    const renderClientInfo = () => {
        return (
            <Client
                client={client}
                edit={edit}
                getClients={getClients}
                setSelected={setSelected}
                handleClose={handleClose}
            />
        )
    }

    const renderVehicles = () => {
        return (
            <ViewVehicles
                client={client}
                edit={edit}
                triggerGetVehicles={triggerGetVehicles}
                selectedVehicle={editVehicle}
                setEditVehicle={setEditVehicle}
                changeTab={changeActiveTab}
                handleClose={handleClose}
            />
        )
    }

    const renderMachinery = () => {
        return (
            <ViewMachinery
                client={client}
                edit={edit}
                triggerGetMachinery={triggerGetMachinery}
                selectedMachinery={editMachinery}
                setEditMachinery={setEditMachinery}
                changeTab={changeActiveTab}
                handleClose={handleClose}
            />
        )
    }

    const renderAddVehicle = () => {
        return (
            <Vehicle
                client={client}
                setTrigger={setTrigger}
                changeTab={changeActiveTab}
                selectedVehicle={editVehicle}
                setEditVehicle={setEditVehicle}
                handleClose={handleClose}
            />
        )
    }

    const renderAddMachinery = () => {
        return (
            <Machinery
                client={client}
                setTriggerMachinery={setTriggerMachinery}
                changeTab={changeActiveTab}
                selectedMachinery={editMachinery}
                setEditMachinery={setEditMachinery}
                handleClose={handleClose}
            />
        )
    }

    let options = [
        {
            title: "Client Details",
            data: renderClientInfo()
        }

    ]

    if (client) {
        if (permissions && permissions.includes("view-vehicles")) {
            options.push({
                title: "Client Vehicles",
                data: renderVehicles()
            })
        }
        if (permissions && permissions.includes("manage-vehicles")) {
            options.push({
                title: "Add Vehicle",
                data: renderAddVehicle()
            })
        }
        if (permissions && permissions.includes("view-machinery")) {
            options.push({
                title: "Client Machinery",
                data: renderMachinery()
            })
        }
        if (permissions && permissions.includes("manage-machinery")) {
            options.push({
                title: "Add Machinery",
                data: renderAddMachinery()
            })
        }

    }

    return (
        <div>
            <Tabs key={options.title} options={options} activeTab={active}/>
        </div>
    )
}

export default ViewClient