import {Button} from "../../components/Button";
import {useEffect, useState} from "react";
import {Input, TextArea} from "../../components/Input";
import {PRODUCTS_API} from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import {getStorage} from "../../utils/storage";

export default function AddBrand({selected, handleClose, getData}){
    const [loading, setLoading] = useState(false)
    const [brand, setBrand] = useState({
        name: "",
        country: "",
        description: "",
    })
    const [error, setError] = useState({})

    useEffect(() => {
        if(selected){
            setBrand({
                name: selected.name,
                country: selected.country,
                description: selected.description
            })
        }
    }, []);

    const handleInput=(e={})=>{
        const {name,value} = e.target
        if(error[name]) delete error[name]
        setBrand(prev=>({...prev, [name]: value }))
    }

    async function validate(){
        const {name } = brand
        let err = {}
        let valid = true
        if(!name||name===""){
            valid=false
            err.name="Brand name is required."
        }
        setError(prev=>({...prev, ...err}))
        return valid
    }

    async function handleSubmit(e){
        e&&e.preventDefault()
        const isValid = await validate()
        if(!isValid) return
        const { token } = await getStorage()
        setLoading(true)
        const {name, country, description} = brand
        const options = {
            method: selected?"PUT":"POST",
            url: selected? `${PRODUCTS_API }/brand/${selected.id}` :`${PRODUCTS_API }/brands`,
            data: {
                name,
                country: country?country: " ",
                description: description?description: " "
            },
            headers: {
                authorization: "Bearer " + token,
            }
        };

        axios(options)
            .then(res=>{
              setLoading(false)
              if(res.data){
                toastMessage("success", `Brand ${selected?"updated": "added"} successfully`)
                  handleClose()
                  getData()
              }
            })
            .catch((err)=>{
              setLoading(false)
              if(!err.response){
                return toastMessage(
                  "error",
                  "Connection failed. This could either be due to network failure or the resource is currently unavailable"
                )
              }
              toastMessage("error", err.response.data.message)
            })

    }
    return(
        <div className="card">
            <div className="card-body">
                <Input label={"Name"} required name={"name"} value={brand.name} error={error.name} onChange={handleInput} />
                <Input label={"Country"} name={"country"} value={brand.country} error={error.country} onChange={handleInput} />
                <TextArea label={"Description"} name={"description"} value={brand.description} onChange={handleInput} />
            </div>
            <div className="card-footer d-flex justify-content-end gap-3">
                <Button className={"btn-secondary"} onPress={handleClose} text={"Cancel"} />
                <Button className={"btn-primary"} onPress={handleSubmit} text={"Submit"} isSubmitting={loading} />
            </div>
        </div>
    )
}