import React, { useState, useEffect } from 'react'
import { Container } from '../components/Container'
import { debounce } from '../utils/debounce'
import { getStorage } from "../utils/storage"
import { Splash } from '../components/Splash';
import userLoggedInTimeout from "../utils/userLoggedInTimeout"

const SplashPage = () => {
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    const load = debounce(()=>{
      getLoggedInUser()
    })
    load()
  }, [])

  const getLoggedInUser = async()=>{
    const user = await getStorage()
    navigateToScreen(user)
  }

  const navigateToScreen=async(user)=>{

    const isTokenExpired = await userLoggedInTimeout(user)

    if(user.token&&!isTokenExpired)
      return (window.location.href = "home/clients")
    window.location.href = "/login";
  }

  return (
    <Container>
      <Splash isLoading={loading} />
    </Container>
  )
}

export default SplashPage