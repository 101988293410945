import {Table} from "../../components/Table";
import {useEffect, useState} from "react";
import {Modal} from "../../components/Modal";
import {PRODUCTS_API} from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import StockUpdate from "./StockUpdate";
import {getStorage} from "../../utils/storage";
import useApp from "../../hooks/useApp";

export default function PartsStock({type, active, setActive}) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({
        pageNumber: 1,
        pageLimit: 20,
        pageCount: "N"
    })
    const [search, setSearch] = useState("")
    const [selected, setSelected] = useState(null)
    const [modal, setModal] = useState(false)
    const {permissions} = useApp()

    useEffect(() => {
        let gd
        if (search) {
            setPagination(prev => ({...prev, pageNumber: 1}))
            gd = setTimeout(() => {
                getData({search, pageNumber: 1})
            }, 1000)
        }
        return () => clearTimeout(gd)
    }, [search])

    useEffect(() => {
        getData()
    }, [active]);

    async function getData(params = {}) {
        const {token} = await getStorage()
        let filters = {}
        if (search) {
            filters.search = search
        }
        const options = {
            url: `${PRODUCTS_API}/parts${type ? type : ""}`,
            method: "GET",
            headers: {
                authorization: "Bearer " + token,
            },
            params: {
                pageNumber: pagination.pageNumber,
                ...filters,
                ...params
            }
        }
        setLoading(true)

        await axios(options)
            .then(res => {
                if (res.data) {
                    setData(res.data)
                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.message) {
                    toastMessage("error", err.response.data.message)
                } else {
                    toastMessage("error", "Failed! Refresh the page to try again.")
                }
            })
        setLoading(false)
    }

    const headers = [
        {title: "Part Number", key: "ART_ARTICLE_NR"},
        // { title: "Part Use", key: "part_use" },
        {title: "Brand", key: "ART_SUP_BRAND"},
        {title: "Price", key: "PRI_PRICE"},
        {title: "Quantity", key: "PRI_STOCK_QUANTITY"},
        {
            title: "Date Updated",
            key: "UPDATED_AT",
            isMoment: true,
            formatTime: "lll",
        },
        // { title: "Action", key: "action" },
    ]
    if (permissions && permissions.includes("manage-all-stock")) {
        headers.push({title: "Action", key: "action"})
    }
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <Table
                        headers={headers}
                        isLoading={loading}
                        data={data}
                        search_text={search}
                        handleSearch={(e) => {
                            setSearch(e.target.value)
                            if (e.target.value === "") {
                                getData()
                            }
                        }}
                        pagination={pagination}
                        handlePagination={(pageNumber) => {
                            setPagination(prev => ({...prev, pageNumber}))
                            getData({pageNumber})
                        }}
                        placeholder={"Search part number"}
                        // rowPress={(row) => {
                        //     setSelected(row)
                        //     setModal(true)
                        // }}
                        actions={[
                            {
                                name: "Update",
                                onPress: (row) => {
                                    setSelected(row)
                                    setModal(true)
                                },
                            }
                        ]}
                    />
                </div>
            </div>
            <Modal
                show={modal}
                title={`Update ${selected && selected.ART_ARTICLE_NR} stock`}
                handleClose={() => (setSelected(), setModal(false))}
            >
                <StockUpdate
                    selected={selected}
                    getData={getData}
                    setActive={setActive}
                    handleClose={() => (setSelected(), setModal(false))}
                />
            </Modal>
        </>
    )
}