import React from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./styles.css";

const Button = (props) => (
  <>
    {props.type === "link" ? (
      <Link
          to={props.path}
        className={`btn ${props.className} ${props.circle && "circle"}`}
        disabled={props.isSubmitting || props.disabled}
        onClick={props.onPress}
      >
        {props.isSubmitting ? (
          <i className="bx bx-loader bx-spin"></i>
        ) : (
          props.text
        )}
      </Link>
    ) : props.type === "link-icon" ? (
      <Link
        className={`btn link-icon ${props.className} ${
          props.circle && "circle"
        }`}
        disabled={props.isSubmitting || props.disabled}
        to={props.route}
      >
        <i className={props.icon}></i>
      </Link>
    ) : (
      <button
        className={`btn ${props.className}  ${props.circle && "circle"}`}
        disabled={props.isSubmitting || props.disabled}
        onClick={props.onPress}
      >
        {props.isSubmitting ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <span>
            {props.icon && <i className={props.icon}></i>}
            <span style={props.icon && { marginLeft: 5 }}>
              {props.text && props.text}
              {props.selected && (
                <b style={{ paddingLeft: 5 }}>{props.selected}</b>
              )}
            </span>
          </span>
        )}
      </button>
    )}
  </>
);

export default Button;
