import React, { useEffect } from "react"
import { Tabs } from "../../components/Tabs"
import { Products } from "../Product"
import Payments from "./Payments"
import Customer from "./Customer"

const Invoice = ({ invoice, handleClose, setSelected }) => {
  useEffect(() => {
    return () => {
      setSelected(null)
    }
  }, [])
  const renderProducts = () => {
    return <Products invoice={invoice} handleClose={handleClose} />
  }
  const renderPayments = () => {
    return <Payments invoice={invoice} handleClose={handleClose} />
  }
  const renderCustomer = () => {
    return <Customer invoice={invoice} handleClose={handleClose} />
  }

  const options = [
    {
      title: "Products Details",
      data: renderProducts(),
    },
    {
      title: "Payments Details",
      data: renderPayments(),
    },
    {
      title: "Customer Details",
      data: renderCustomer(),
    },
  ]

  return <Tabs options={options} />
}

export default Invoice
