import React from "react";
import LogoImage from "../../assets/logo.png";
import LogoImageWhite from "../../assets/logo-white.jpg";
import "./styles.css";

const Logo = ({ containerWidth = 65, type }) => (
  <div className="logo-container" style={{ width: containerWidth }}>
    <img src={type === "white" ? LogoImageWhite : LogoImage} alt="NTSA Logo" />
  </div>
);

export default Logo;
