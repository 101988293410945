export const menus = [
  // {
  //   title: "",
  //   menus: [
  //     {
  //       title: "Analytics",
  //       icon: "bx bxs-home",
  //       route: "/home/dashboard",
  //     },
  //   ],
  // },
  {
    title: "PARTSFINDER",
    menus: [
      {
        title: "Clients",
        icon: "bx bxs-group",
        route: "/home/clients",
      },
      {
        title: "Vehicles",
        icon: "bx bxs-car",
        route: "/home/vehicles",
      },
      {
        title: "Machinery",
        icon: "bx bxs-hard-hat",
        route: "/home/machinery",
      },
    ],
  },
  {
    title: "E-CATALOGUE",
    menus:[
        {
          title: "Sales",
          icon: "bx bxs-shopping-bag",
          route: "/home/sales",
        },
        {
          title: "Banners",
          icon: "bx bx-images",
          route: "/home/banner",
        },
      // {
      //   title: "Transactions",
      //   icon: "bx bx-money",
      //   route: "/home/transactions",
      // }
    ]
  },
  {
    title: "VEHICLE PARTS",
    menus: [
        {
          title: "All Stock",
          icon: "bx bxs-store",
          route: "/home/stock",
        },
        {
          title: "Available Stock",
          icon: "bx bxs-store",
          route: "/home/available-stock",
        },
        {
          title: "Out of Stock",
          icon: "bx bxs-store",
          route: "/home/out-of-stock",
        },
    ]
  },
  {
    title: "HEAVY COMMERCIAL",
    menus: [
        {
          title: "Parts",
          icon: "bx bx-hard-hat",
          route: "/home/products",
        },
        {
          title: "Brands",
          icon: "bx bxs-factory",
          route: "/home/brands",
        }
    ]
  },

  // {
  //   title: "REPORTS",
  //   menus: [
  //     {
  //       title: "Products",
  //       icon: "bx bxs-store-alt",
  //       route: "/home/reports/products",
  //     },
  //     // {
  //     //   title: "Sales",
  //     //   icon: "bx bxs-shopping-bag",
  //     //   route: "/home/reports/sales",
  //     // },
  //     {
  //       title: "Top Search",
  //       icon: "bx bx-search-alt",
  //       route: "/home/reports/search",
  //     },
  //     // {
  //     //   title: "Transactions",
  //     //   icon: "bx bx-money",
  //     //   route: "/home/reports/transactions",
  //     // },
  //     {
  //       title: "Clients",
  //       icon: "bx bxs-group",
  //       route: "/home/reports/clients",
  //     },
  //   ],
  // },
  {
    title: "OPTIONS",
    menus: [
      {
        title: "Users",
        icon: "bx bxs-user",
        route: "/home/users",
      },
      {
        title: "Settings",
        icon: "bx bxs-cog",
        route: "/home/settings",
      },
    ],
  },
];
