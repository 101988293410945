import moment from "moment"
import React, { useRef } from "react"
import { Link } from "react-router-dom"
import deepFind from "../../utils/deepFind"
import { Button, DropdownButton } from "../Button"
import { Input } from "../Input"
import { Pagination } from "../Pagination"
import "./styles.css"
import { LoadingSpinner } from "../LoadingSpinner"
import { CSVLink } from "react-csv"

let copyData = []
class Table extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			currentPage: props.pagination.pageNumber
				? props.pagination.pageNumber
				: 1,
			numPerpage: props.pagination.pageLimit,
			search_text: "",
			data: props.data,
		}
		this.csvRef = React.createRef()
	}

	componentDidMount(props) {
		copyData = this.props.data
	}

	componentDidUpdate(prevProps, nextState) {
		if (nextState.data !== this.props.data) {
			this.setState({
				data: this.props.data,
			})
		}
	}

	handleSearch(e) {
		let new_data = [],
			search_text = e.target.value

		for (let i = 0; i < copyData.length; i++) {
			if (
				JSON.stringify(copyData[i])
					.toUpperCase()
					.indexOf(search_text.toUpperCase()) !== -1
			) {
				new_data.push(copyData[i])
			}
		}

		this.setState({
			data: new_data,
			search_text,
		})
	}

	render() {
		const {
			handlePagination,
			pagination,
			headers,
			actions,
			rowPress,
			no_bordered,
			filters,
			showAdd,
			noSearch=false,
			addButtonText,
			handleAddPressed,
			isLoading,
			placeholder,
			handleSort,
			sortOrder,
			sortColumn,
			downloadCert,
			tab,
			deleteBulk,
			handleDeleteBulk,
		} = this.props

		const { numPerpage, data } = this.state

		const currentPage = pagination.pageNumber
		const totalPageCount = pagination.pageCount

		const currentData = data
		const firstPage = 1
		const lastPage = pagination.pageCount

		const paginate = async (numPage) => {
			await handlePagination(numPage)
		}

		const nextPage = async () => {
			if (currentPage === lastPage) return
			await handlePagination(currentPage + 1)
		}

		const prevPage = async () => {
			if (currentPage === firstPage) return
			await handlePagination(currentPage - 1)
		}

		return (
			<>
				{this.props.hideHeader ? null : (
					<div
						className="card-header"
						style={{
							paddingLeft: 0,
							paddingRight: 0,
							display: "block",
							alignItems: "flex-start",
						}}
					>
						<div className="row d-flex flex-wrap">
							{!noSearch&&
							<div className="col-md-auto flex-fill">
								<div className="table-filters">
									<Input
										placeholder={placeholder ? placeholder : "Search..."}
										value={
											this.props.search_text
												? this.props.search_text
												: this.state.search_text
										}
										onChange={
											this.props.handleSearch
												? tab
													? (e) => this.props.handleSearch(tab, e)
													: this.props.handleSearch
												: this.handleSearch.bind(this)
										}
										iconRight="bx bx-search"
										inputContainerStyle={{ marginBottom: 0 }}
									/>
								</div>
							</div>
							}
							{/* <div className="col-md-auto flex-fill"></div> */}
							<div className="col-md-auto flex-fill">
								<div className="d-md-flex d-lg-flex gap-2 justify-content-end flex-wrap">
									{deleteBulk && (
										<Button
											className="btn-danger btn-sm"
											onPress={() => handleDeleteBulk("bulk")}
											text="Delete"
											icon={this.props.addIcon?this.props.addIcon:"bx bx-plus"}
										/>
									)}
									{filters &&
										filters.map((filter, f) => {
											if (filter.button_type === "dropdown") {
												return (
													<div
														style={filter.disabled ? { display: "none" } : {}}
														key={f}
													>
														<DropdownButton
															className="btn-gray btn-sm"
															text={filter.title}
															clickBehaviorId="dropdownMenuClickableInside"
															autoCloseType="outside"
															selected={filter.selected}
															isSearchable={filter.isSearchable}
															options={filter.options}
															onSelectDropdownItem={filter.onSelectDropdownItem}
															search_text={filter.search_text}
															onChangeSearch={filter.onChangeSearch}
															selected_item={filter.selected_item}
															isLoading={filter.isLoading}
															isMulti={filter.isMulti}
															isList={filter.isList}
															default_key={filter.default_key}
															handleReset={filter.handleReset}
															icon={filter.icon}
														>
															{filter.options.map((option, o) => {
																return (
																	<li key={o}>
																		{option.isDownloadCsv &&
																		option.csvData.length > 0 ? (
																			<CSVLink
																				filename={option.filename + ".csv"}
																				data={
																					option && option.csvData
																						? option.csvData
																						: []
																				}
																				id="CSVLink"
																				asyncOnClick={true}
																				onClick={option.onPress}
																				className="dropdown-item"
																				ref={this.csvRef}
																			>
																				{option.name
																					? option.name
																					: option.label
																					? option.label
																					: ""}
																			</CSVLink>
																		) : (
																			<Link
																				onClick={() => option.onPress(option)}
																				className="dropdown-item"
																				to="#"
																			>
																				{option.name
																					? option.name
																					: option.label
																					? option.label
																					: ""}
																			</Link>
																		)}
																	</li>
																)
															})}
														</DropdownButton>
													</div>
												)
											} else {
												return (
													<Button
														selected={filter.selected}
														className="btn-gray btn-sm"
														text={filter.title}
														onPress={filter.onPress}
														icon={filter.icon ? filter.icon : "bx bx-filter"}
													/>
												)
											}
										})}
									{showAdd && (
										<Button
											className="btn-primary btn-sm"
											onPress={handleAddPressed}
											text={addButtonText}
											icon={this.props.addIcon?this.props.addIcon:"bx bx-plus"}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
				<div className="table-responsive">
					<table
						className={`table table-hover table-condensed ${
							!no_bordered && "table-bordered"
						}`}
					>
						<thead>
							<tr>
								{headers.map((item, i) => {
									return (
										<>
											{item.key === "selectAll" ? (
												<th>
													<input
														type="checkbox"
														onChange={() => item.onSelect("all")}
													/>
												</th>
											) : (
												<th key={i}>
													{item.title}
													{item.sort && (
														<button
															className="sort-btn"
															onClick={() => handleSort(item.key, tab)}
														>
															{(sortOrder === -1 || sortOrder === "desc") &&
															sortColumn === item.key ? (
																<i className="bx bx-up-arrow-alt"></i>
															) : (
																<i className="bx bx-down-arrow-alt"></i>
															)}
														</button>
													)}
												</th>
											)}
										</>
									)
								})}
							</tr>
						</thead>
						<tbody className={this.props.tableBodyClass}>
							{isLoading ? (
								<tr>
									<td colSpan={headers.length}>
										<LoadingSpinner />
									</td>
								</tr>
							) : currentData.length === 0 ? (
								<tr>
									<td colSpan={13} align="center">
										No data found
									</td>
								</tr>
							) : (
								currentData.map((item, i) => {
									let hasActionBtn = false

									return (
										<tr key={i}>
											{headers.map((header, h) => {
												hasActionBtn = header.key === "action"
												let date = moment(item[header.key]).format(
													header.formatTime
												)
												let deep_f_text = deepFind(item, header.key)

												const text =
													header.isMoment &&
													item[header.key] &&
													item[header.key] !== ""
														? date === "Invalid date"
															? deep_f_text
															: date
														: deep_f_text
												return (
													<>
														{header.key === "selectAll" && (
															<td>
																<input
																	type="checkbox"
																	onChange={() => header.onSelect(item)}
																	checked={item && item.selected}
																/>
															</td>
														)}
														{header.key === "photo" && (
															<td>
																<img src={item[header.key]} />
															</td>
														)}
														{!hasActionBtn &&
															header.key !== "photo" &&
															header.key !== "selectAll" && (
																<td
																	className="data"
																	onClick={() =>
																		header.key !== "action"
																			? rowPress
																				? rowPress(item)
																				: header.isLink
																				? downloadCert(item[header.key])
																				: null
																			: null
																	}
																	title={text}
																	style={{
																		whiteSpace: "nowrap",
																		textOverflow: "ellipsis",
																		maxWidth: header.isBadge?"auto":200,
																		overflow: "hidden",
																	}}
																>
																	{header.isLink ? (
																		<a
																			style={{ color: "blue" }}
																			target="_blank"
																			href={text}
																		>
																			{text}
																		</a>
																	) : (
																		<span
																			style={
																				header.isLink || text === "Success"
																					? { color: "blue" , wordWrap: "break-word"}
																					: text === "Failed"
																					? { color: "var(--danger)",wordWrap: "break-word" }
																					: {wordWrap: "unset"}
																			}
																		>
																			{header.isBadge ? (
																				<span className="d-flex justify-content-center rounded rounded-sm">
																					{text}
																				</span>
																			) : (
																				text && text.toString()
																			)}
																		</span>
																	)}
																</td>
															)}
													</>
												)
											})}
											{hasActionBtn && (
												<td>
													<DropdownButton
														text="Action"
														className="btn-default btn-sm"
														hideFilterIcon
													>
														{actions &&
															actions.map((action, a) => {
																return (
																	<Link
																		className="dropdown-item"
																		to={action.route ? action.route : "#"}
																		onClick={() => action.onPress(item, i)}
																	>
																		{action.name}
																	</Link>
																)
															})}
													</DropdownButton>
												</td>
											)}
										</tr>
									)
								})
							)}
						</tbody>
					</table>
				</div>
				{(totalPageCount > 0 || totalPageCount === "N") && totalPageCount && (
					<Pagination
						totalPageCount={pagination.pageCount}
						numberPerpage={numPerpage}
						paginate={paginate}
						nextPage={nextPage}
						prevPage={prevPage}
						firstPage={firstPage}
						lastPage={lastPage}
						currentPage={pagination.pageNumber}
						tab={tab}
					/>
				)}
			</>
		)
	}
}

export default Table
