import React, { useState, useEffect } from 'react'
import { Button } from '../../components/Button'
import { Input } from '../../components/Input'
import { VEHICLES_API } from '../../constants/api'
import { getStorage } from '../../utils/storage'
import toastMessage from '../../utils/toastMessage'
import axios from 'axios'
import permissions from "../../constants/permissions";
import useApp from "../../hooks/useApp";

const Machinery = (props) => {
  const { 
    client, 
    selectedMachinery, 
    handleClose, 
    setEditMachinery, 
    changeTab, 
    setTriggerMachinery,
    disabled
  } = props

  const defualtVehicle = {
    type: "",
    make: "",
    model: "",
    year: "",
    capacity: '',
  }

  const [vehicle, setVehicle] = useState(defualtVehicle)
  const [error, setError] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const {permissions} = useApp()

  useEffect(()=>{
    if(selectedMachinery){
      setVehicle(selectedMachinery)
    }
  }, [selectedMachinery])

  const validateVehicle = async()=>{
    const {
      type,
      make,
      model,
      capacity,
      year
    } = vehicle

    let err = {}, isValid = true

    if(!type || type === ""){
      err.type = "Machinery type must be provided"
      isValid = false      
    }
    if(!make || make === ""){
      err.make = "Machinery Make must be provided"
      isValid = false      
    }
    if(!model || model === ""){
      err.model = "Machinery Model must be provided"
      isValid = false      
    }
    if(!capacity || capacity === ""){
      err.capacity = "Engine Capacity must be provided"
      isValid = false      
    }
    if(!year || year === ""){
      err.year = "Machinery Year of manufacture must be provided"
      isValid = false      
    }

    return isValid
  }

  const handleSubmit = async()=>{
    let valid = await validateVehicle()
    if(!valid) return
    

    setSubmitting(true)

    const { token } = await getStorage()
    let method = selectedMachinery ? "PUT" : "POST"
    let path = selectedMachinery ? `/machinery/${selectedMachinery.id}`: `/machinery`

    const {
      type,
      make,
      model,
      capacity,
      year
    } = vehicle

    let params = {}
    if(!selectedMachinery){
      params.clientId = client.id
    }

    const options = {
      method,
      url: VEHICLES_API + path,
      headers: {
        authorization: "Bearer " + token,
      },
      data: {
        ...params,
        type,
        make,
        model,
        capacity,
        year
      }
    };

    axios(options)
    .then(async res=>{
      setSubmitting(false)
      if(res.status === 201){
        await setTriggerMachinery(prev =>!prev)
        setVehicle(defualtVehicle)
        changeTab("client machinery")
        return toastMessage(
          "success",
          "Machinery added successfully."
        )
      }

      if(res.status === 200){
        setEditMachinery(null)
        setVehicle(defualtVehicle)
        changeTab("client machinery")
        return toastMessage(
          "success",
          "Machinery updated successfully."
        )
      }
    })
    .catch((err)=>{
      setSubmitting(false)
      console.log("Added error: ", err)

      if(!err.response || !(err.response&&err.response.data)){
        return toastMessage(
          "error",
          "Connection failed. This could either be due to network failure or the resource is currently unavailable"
        )
      }
      toastMessage("error", err.response.data.message)
    })
  }

  const handleInput = (e)=>{
    const { name, value } = e.target

    setVehicle(prev=>({
        ...prev,
        [name]: value
    }))
  }

  if (!(permissions&&permissions.includes("manage-machinery"))) {
    return null
  }

  return (
    <>
    <div className="modal-body">
      <div className="row">
        <Input
          name="type"
          error={error.type}
          onChange={handleInput}
          type="text"
          required
          disabled={disabled}
          value={vehicle.type}
          label="Machinery Type:"
        />

        <div className="col-md-6">
          <Input
            name="make"
            error={error.make}
            onChange={handleInput}
            type="text"
            disabled={disabled}
            required
            value={vehicle.make}
            label="Machinery Make:"
          />
        </div>

        <div className="col-md-6">
          <Input
            name="model"
            error={error.model}
            onChange={handleInput}
            type="text"
            disabled={disabled}
            required
            value={vehicle.model}
            label="Machinery Model:"
          />
        </div>

       
        <div className="col-md-6">
          <Input
            name="year"
            error={error.year}
            onChange={handleInput}
            type="text"
            disabled={disabled}
            required
            value={vehicle.year}
            label="Year of Manufacture:"
          />
        </div>

        <div className="col-md-6">
          <Input
            name="capacity"
            error={error.capacity}
            onChange={handleInput}
            type="text"
            disabled={disabled}
            required
            value={vehicle.capacity}
            label="Engine Capacity:"
          />
        </div>

      </div>
    </div>
    <div className="modal-footer d-flex gap-3">
      <Button  
        text="Cancel" 
        className="btn-secondary" 
        onPress={()=> handleClose(false)}
        />
      <Button
        isSubmitting={submitting}
        onPress={handleSubmit}
        disabled={disabled}
        text={selectedMachinery ? "Update": "Submit"}
        className="btn-primary"
      />
    </div>
  </>
  )
}

export default Machinery
