import { menus } from "../constants/sidebar-menus"

export default async function checkPermissions(permissions, location) {
  if (!permissions || !Array.isArray(permissions)) return []

  let selectedMenus = []

  // PARTSFINDER
  let operatorMenu = menus.find((menu) => menu.title === "PARTSFINDER")
  let operatorSubMenus = []
  if (
    permissions.includes("view-clients") ||
    permissions.includes("view-added-clients")
  ) {
    operatorSubMenus.push({
      title: "Clients",
      icon: "bx bxs-group",
      route: "/home/clients",
    })
  }
  if (
    permissions.includes("view-vehicles") ||
    permissions.includes("view-added-vehicles")
  ) {
    operatorSubMenus.push({
      title: "Vehicles",
      icon: "bx bxs-car",
      route: "/home/vehicles",
    })
  }
  if (
    permissions.includes("view-machinery") ||
    permissions.includes("view-added-machinery")
  ) {
    operatorSubMenus.push({
      title: "Machinery",
      icon: "bx bxs-hard-hat",
      route: "/home/machinery",
    })
  }
  if (operatorSubMenus.length > 0) {
    selectedMenus.push({ ...operatorMenu, menus: operatorSubMenus })
  } else {
    if (
      location.pathname === "/home/vehicles" ||
      location.pathname === "/home/clients" ||
      location.pathname === "/home/machinery"
    ) {
      window.location.href = "/home/unauthorized"
    }
  }

  //E-CATALOGUE
  let busMenu = menus.find((menu) => menu.title === "E-CATALOGUE")
  let busSubMenus = []
  if (permissions.includes("view-sales")) {
    busSubMenus.push({
      title: "Sales",
      icon: "bx bxs-shopping-bag",
      route: "/home/sales",
    })
  }
  if (permissions.includes("view-banners")) {
    busSubMenus.push({
      title: "Banners",
      icon: "bx bx-images",
      route: "/home/banner",
    })
  }
  if (busSubMenus.length > 0) {
    selectedMenus.push({ ...busMenu, menus: busSubMenus })
  }

  // VEHICLE PARTS
  let fleetMenu = menus.find((menu) => menu.title === "VEHICLE PARTS")
  let fleetSubMenus = []
  if (permissions.includes("view-all-stock")) {
    fleetSubMenus.push({
      title: "All Stock",
      icon: "bx bxs-store",
      route: "/home/stock",
    })
  }
  if (permissions.includes("view-available-stock")) {
    fleetSubMenus.push({
      title: "Available Stock",
      icon: "bx bxs-store",
      route: "/home/available-stock",
    })
  }
  if (permissions.includes("view-out-of-stock")) {
    fleetSubMenus.push({
      title: "Out of Stock",
      icon: "bx bxs-store",
      route: "/home/out-of-stock",
    })
  }
  if (fleetSubMenus.length > 0) {
    selectedMenus.push({ ...fleetMenu, menus: fleetSubMenus })
  } else {
    if (
      location.pathname === "/home/stock" ||
      location.pathname === "/home/available-stock" ||
      location.pathname === "/home/out-of-stock"
    ) {
      window.location.href = "/home/unauthorized"
    }
  }

  // HEAVY COMMERCIAL
  let mviMenu = menus.find((menu) => menu.title === "HEAVY COMMERCIAL")
  let mviSubMenus = []
  if (permissions.includes("view-hcv-parts")) {
    mviSubMenus.push({
      title: "Parts",
      icon: "bx bx-hard-hat",
      route: "/home/products",
    })
  }
  if (permissions.includes("view-brands")) {
    mviSubMenus.push({
      title: "Brands",
      icon: "bx bxs-factory",
      route: "/home/brands",
    })
  }
  if (mviSubMenus.length > 0) {
    selectedMenus.push({ ...mviMenu, menus: mviSubMenus })
  } else {
    if (
      location.pathname === "/home/brands" ||
      location.pathname === "/home/products"
    ) {
      window.location.href = "/home/unauthorized"
    }
  }
  // OPTIONS
  let vendorsMenu = menus.find((menu) => menu.title === "OPTIONS")
  let vendorsSubMenus = []
  if (permissions.includes("view-users")) {
    vendorsSubMenus.push({
      title: "Users",
      icon: "bx bxs-user",
      route: "/home/users",
    })
  }
  vendorsSubMenus.push({
    title: "Settings",
    icon: "bx bxs-cog",
    route: "/home/settings",
  })
  if (vendorsSubMenus.length > 0) {
    selectedMenus.push({ ...vendorsMenu, menus: vendorsSubMenus })
  }
  if (
    !vendorsSubMenus.find((item) => item.title === "Users") &&
    location.pathname === "/home/users"
  ) {
    window.location.href = "/home/unauthorized"
  }
  return selectedMenus
}
