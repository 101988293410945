import React from "react";
import "./styles.css";

class Button extends React.Component {
  handlePress(e) {
    e && e.preventDefault();

    let class_list = document.getElementById("custom-dropdown-menu").classList;
    if (class_list && class_list.contains("show")) {
      class_list.remove("show");

      return;
    }

    class_list.add("show");
  }

  render() {
    return (
      <>
        <div className="custom-dropdown" id="custom-dropdown">
          <button
            className={`btn ${this.props.className} custom-d-button`}
            type="button"
            onClick={this.handlePress.bind(this)}
          >
            {this.props.text}
            <i className="bx bxs-down-arrow"></i>
          </button>
          <div id="custom-dropdown-menu" className="custom-dropdown-menu test">
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}

export default Button;
