const permissions = [
  {
    label: "View All Clients",
    value: "view-clients",
  },
  {
    label: "View Added Clients",
    value: "view-added-clients",
  },
  {
    label: "Manage Clients",
    value: "manage-clients",
  },
  {
    label: "View All Vehicles",
    value: "view-vehicles",
  },
  {
    label: "View Added Vehicles",
    value: "view-added-vehicles",
  },
  {
    label: "Manage Vehicles",
    value: "manage-vehicles",
  },
  {
    label: "View All Machinery",
    value: "view-machinery",
  },
  {
    label: "View Added Machinery",
    value: "view-added-machinery",
  },
  {
    label: "Manage Machinery",
    value: "manage-machinery",
  },
  {
    label: "View Sales",
    value: "view-sales",
  },
  {
    label: "Manage Sales",
    value: "manage-sales",
  },
  {
    label: "View Banners",
    value: "view-banners",
  },
  {
    label: "Manage Banners",
    value: "manage-banners",
  },
  {
    label: "View All Stock",
    value: "view-all-stock",
  },
  {
    label: "Manage All Stock",
    value: "manage-all-stock",
  },
  // {
  //     label: "View Available Stock",
  //     value: "view-available-stock"
  // },
  // {
  //     label: "Manage Available Stock",
  //     value: "manage-available-stock"
  // },
  // {
  //     label: "View Out of Stock",
  //     value: "view-out-of-stock"
  // },
  //  {
  //     label: "Manage Out of Stock",
  //     value: "manage-out-of-stock"
  // },
  {
    label: "View HCV Parts",
    value: "view-hcv-parts",
  },
  {
    label: "Manage HCV Parts",
    value: "manage-hcv-parts",
  },
  {
    label: "View Brands",
    value: "view-brands",
  },
  {
    label: "Manage Brands",
    value: "manage-brands",
  },
  {
    label: "View Users",
    value: "view-users",
  },
  {
    label: "Manage Users",
    value: "manage-users",
  },
]

export default permissions
