import React, { useState } from "react"
import FileUpload from "../../components/FileUpload/FileUpload"
import { Button } from "../../components/Button"
import Papa from "papaparse"
import toastMessage from "../../utils/toastMessage"
import convertToCSV from "../../utils/convertToCSV"
import useApp from "../../hooks/useApp"
import { useHistory } from "react-router-dom"

const Upload = ({ handleClose, setViewUploads }) => {
	const [data, setData] = useState([])
	const [table, setTable] = useState([])
	const [downloading, setDownloading] = useState(false)
	const { setUploads } = useApp()
	const history = useHistory()

	const handleDrop = (files) => {
		console.log("Drop file: ", files)
		if (
			!files ||
			files.length === 0 ||
			!files[0].type ||
			!files[0].type.toLowerCase().includes("csv")
		) {
			toastMessage(
				"error",
				"Missing file or wrong data format. The acceptable file format is .csv"
			)
		}

		Papa.parse(files[0], {
			header: true,
			skipEmptyLines: true,
			complete: (res) => {
				const { data, errors, meta } = res
				setTable(meta.fields)
				if (!meta.fields.includes("Product Name")) {
					toastMessage("error", "Wrong data field(s)")
					return
				}
				if (!meta.fields.includes("Category")) {
					toastMessage("error", "Wrong data field(s)")
					return
				}
				if (!meta.fields.includes("Sub Category")) {
					toastMessage("error", "Wrong data field(s)")
					return
				}
				if (!meta.fields.includes("Product Type")) {
					toastMessage("error", "Wrong data field(s)")
					return
				}
				if (!meta.fields.includes("Tag(optional)")) {
					toastMessage("error", "Wrong data field(s)")
					return
				}
				if (!meta.fields.includes("Description(Min 100words)")) {
					toastMessage("error", "Wrong data field(s)")
					return
				}
				if (!meta.fields.includes("Additional Info(optional)")) {
					toastMessage("error", "Wrong data field(s)")
					return
				}
				if (!meta.fields.includes("Price")) {
					toastMessage("error", "Wrong data field(s)")
					return
				}
				if (!meta.fields.includes("Slashed Price(Optional)")) {
					toastMessage("error", "Wrong data field(s)")
					return
				}
				if (!meta.fields.includes("Quantity")) {
					toastMessage("error", "Wrong data field(s)")
					return
				}
				setData(data)
				setUploads(data)
				history.push("products/upload")
				handleClose()
			},
		})
	}

	const onDownload = async (e) => {
		e && e.preventDefault()
		setDownloading(true)

		const data = [
			{
				"Product Name": "",
				Category: "",
				"Sub Category": "",
				"Product Type": "",
				"Tag(optional)": "",
				"Description(Min 100words)": "",
				"Additional Info(optional)": "",
				Price: "",
				"Slashed Price(Optional)": "",
				Quantity: "",
			},
		]
		const csvUrl = convertToCSV(data)

		if (csvUrl) {
			const link = document.createElement("a")
			link.href = csvUrl
			link.download = "Upload Products.csv"
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
		setDownloading(false)
	}

	return (
		<div className="card">
			<div className="card-body">
				<FileUpload
					onDownload={onDownload}
					downloading={downloading}
					onDrop={handleDrop}
					format=".csv"
				/>
			</div>
			<div className="card-footer d-flex gap-4 justify-content-end">
				<Button text="Cancel" className="btn-default" onPress={handleClose} />
			</div>
		</div>
	)
}

export default Upload
