import React from "react";
import { Button } from "../Button";
import "./styles.css"

const DeleteOperator = (props) => {
  return (
    <>
      <div className="delete-body gap-3">
        <i className="bx bxs-error"></i>
        <p className="message">
          {props.message
            ? props.message
            : `Are you sure you want to delete ${props.name}? `}
        </p>
      </div>
      <div className="modal-footer gap-3">
        <Button
          text="Cancel"
          className="btn-secondary"
          onPress={() => props.handleClose()}
        />

        <Button
          text="Delete"
          className=" btn-delete"
          onPress={() => props.onDelete()}
          isSubmitting={props.isSubmitting}
        />
      </div>
    </>
  );
};

export default DeleteOperator;
