import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { AUTH_API, USERS_API } from '../../constants/api';
import { getStorage } from '../../utils/storage';
import toastMessage from '../../utils/toastMessage';
import "./style.css"

const Settings = () => {
    const [user, setUser] = useState({})
    const [error, setError] = useState({})
    const [creds, setCreds] = useState({
        password: "",
        confirmPassword: ""
    })
    const [editing, setEditing] = useState(false)
    const [editingPassword, setEditingPassword] = useState(false)

    useEffect(()=>{
      const getUser = async()=>{
          const user = await getStorage()
          if(!user.token){
              return (window.location.href = "/login");
          }
          await setUser(user)
      }
      getUser()
    }, [])

    const handleInput=(e)=>{
        const { name, value } = e.target

        if(error[name]){
            delete error[name]
        }

        setUser(prev=>({
            ...prev,
            [name]: value
        }))
    }

    const validateProfile=async()=>{
        let isValid = true,
            {
                firstName,
                lastName,
                email,
                phone
            } = user,
            err={}

        if(!firstName || firstName === ""){
            isValid = false
            err.firstName = "First Name must be provided"
        }
        if(!lastName || lastName === ""){
            isValid = false
            err.lastName = "Last Name must be provided"
        }
        if(!phone || phone === ""){
            isValid = false
            err.phone = "Phone Number must be provided"
        }
        if(!email || email === ""){
            isValid = false
            err.email = "Email address must be provided"
        }

        await setError(err)
        return isValid
    }

    const validatePassword=async()=>{
        let { password, confirmPassword } = creds, isValid = true, err = {}

        if(!password || password === ""){
            err.password = "Password MUST be provided"
            isValid=false
        }
        if(password&&password.length < 6){
            err.password = "Password MUST be atleast 6 digits long"
            return false
        }
        if(!confirmPassword || confirmPassword === ""){
            err.confirmPassword = "Password confirmation MUST be provided"
            isValid=false
        }

        if(password&&confirmPassword&&(password!==confirmPassword)){
            err.confirmPassword = "Password confirmation do not match"
            isValid=false
        }

        await setError(err)
        return isValid
    }

    const handleEdit=async()=>{
        let valid = await validateProfile()
        if(!valid) return
        if(!user.id) return

        setEditing(true)

        const { firstName, lastName, email, phone } = user

        const options = {
          method: "PUT",
          url: USERS_API + "/user/"+user.id,
          headers: {
            authorization: "Bearer " + user.token,
          },
          data: {
            firstName,
            lastName,
            email,
            phone
          }
        };
      
        axios(options)
          .then(res=>{
            setEditing(false)
            if(res.status === 200){

              toastMessage(
                "success",
                "User profile updated successfully. Logging out now..."
              )

              setTimeout(()=>{
                return (window.location.href = "/login");
              }, 1500)
      
            }
          })
          .catch((err)=>{
            setEditing(false)
            if(!err.response || !(err.response&&err.response.data)){
              return toastMessage(
                "error",
                "Connection failed. This could either be due to network failure or the resource is currently unavailable"
              )
            }
            toastMessage("error", err.response.data.message)
          })
    }

    const handleEditPassword = async()=>{
        let valid = await validatePassword()
        if(!valid) return
        if(!user.id) return

        setEditingPassword(true)

        const options = {
            method: "PUT",
            url: AUTH_API + "/reset-password",
            headers: {
              authorization: "Bearer " + user.token,
            },
            data: {
              userId: user.id,
              ...creds
            }
        };
        axios(options)
        .then(res=>{
          setEditingPassword(false)
          if(res.status === 200){

            toastMessage(
              "success",
              "User password updated successfully. Logging out now..."
            )

            setTimeout(()=>{
              return (window.location.href = "/login");
            }, 1500)
    
          }
        })
        .catch((err)=>{
          setEditingPassword(false)
          if(!err.response || !(err.response&&err.response.data)){
            return toastMessage(
              "error",
              "Connection failed. This could either be due to network failure or the resource is currently unavailable"
            )
          }
          toastMessage("error", err.response.data.message)
        })

    }
    return (
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <i className="bx bx-card" style={{ marginRight: 5 }}></i>
                  Profile Info
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        label="Firstname"
                        type="text"
                        required
                        name="firstName"
                        onChange={handleInput}
                        error={error.firstName}
                        value={user.firstName}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label="Lastname"
                        type="text"
                        required
                        name="lastName"
                        onChange={handleInput}
                        error={error.lastName}
                        value={user.lastName}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label="Email Address"
                        required
                        name="email"
                        onChange={handleInput}
                        error={error.email}
                        value={user.email}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label="Phone number"
                        required
                        name="phone"
                        onChange={handleInput}
                        error={error.phone}
                        value={user.phone}
                      />
                    </div>

                    <div className="col-md-12">
                      <Button
                        onPress={handleEdit}
                        isSubmitting={editing}
                        className="btn-primary"
                        text="Edit Profile"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Settings