import {USERS_API} from "../constants/api";
import axios from "axios";
import toastMessage from "../utils/toastMessage";
import {useState} from "react";
import {Input} from "../components/Input";
import {Button} from "../components/Button";
import {Link, useHistory} from "react-router-dom";

export default function CreatePassword(){
    const [sending, setSending] = useState(false)
    const [error, setError] = useState({})
    const [creds, setCreds] = useState({
        new_password: "",
        confirm_password: ""
    })
    const history = useHistory()

    const handleInput = (name, value) => {
        if (error[name]) {
            delete error[name]
        }
        setCreds((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    async function validate(){
        let valid = true
        let err = {}
        const {new_password, confirm_password} = creds
        if(!new_password){
            err.new_password = "The new password is required."
            valid = false
        }
        if(!confirm_password){
            err.confirm_password = "The confirm password is required."
            valid = false
        }
        if (new_password !== confirm_password) {
            err.confirm_password = "The confirm password does not match the password above."
            valid = false
        }
        setError(err)
        return valid
    }

    async function submit(e){
        e&&e.preventDefault()
        const token =  history.location&&history.location.search&&history.location.search.split("=")[1]
        if(!token) return

        const isValid = await validate()
        if(!isValid) return
        const options = {
            url: `${USERS_API}/create-password`,
            method: "PUT",
            data:{
                password: creds.confirm_password
            },
            headers: {
                authorization: "Bearer " + token,
            },
        }
        setSending(true)
        axios(options)
            .then(res=>{
                if(res.data){
                    setSending(false)
                    toastMessage("success", "Password created successfully.")
                    window.location.href = "/login"
                }
            })
            .catch(err=>{
                setSending(false)
                if(!err.response){
                    return toastMessage(
                        "error",
                        "Connection failed. This could either be due to network failure or the resource is currently unavailable"
                    )
                }
                toastMessage("error", err.response.data.message)
            })
    }


    return (
        <div className="form-container">
            <div className="col-md-4">
                <div className="card">
                    <div className="card-header">
                        <div>
                            <h1>
                                <b>Change Password?</b>
                            </h1>
                        </div>
                    </div>
                    <div className="card-body">
                        <form className="needs-validation" noValidate>
                            <Input
                                label="New password:"
                                value={creds.new_password}
                                onChange={(e) => handleInput("new_password", e.target.value)}
                                error={error.new_password}
                                required
                                placeholder="Enter new password"
                                type="password"
                                showRightContent={true}
                            />
                            <Input
                                label="Confirm password:"
                                value={creds.confirm_password}
                                onChange={(e) =>   handleInput("confirm_password", e.target.value)}
                                error={error.confirm_password}
                                required
                                placeholder="Confirm password"
                                type="password"
                                showRightContent={true}
                            />
                            <Button
                                isSubmitting={sending}
                                onPress={submit}
                                className=" btn-primary w-100"
                                text="Submit"
                            />
                            <Link link="/login" className=" btn btn-link w-100" >
                                Return to Login
                            </Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}