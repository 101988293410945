export const APP_NAME = "UNIFILTERS-PARTSFINDER"

//PRODUCTION LINKS

export const AUTH_API = "https://api.unifilterskenya.com"
export const CLIENTS_API = "https://api.unifilterskenya.com"
export const PRODUCTS_API = "https://api.unifilterskenya.com"
export const VEHICLES_API = "https://api.unifilterskenya.com"
export const USERS_API = "https://api.unifilterskenya.com"

// DEV LINKS

// export const AUTH_API = "http://localhost:8080"
// export const CLIENTS_API= "http://localhost:8080"
// export const PRODUCTS_API= "http://localhost:8080"
// export const VEHICLES_API= "http://localhost:8080"
// export const USERS_API= "http://localhost:8080"
