import {Button} from "../../components/Button";
import React, {useState} from "react";
import {Select} from "../../components/Input";
import {getStorage} from "../../utils/storage";
import {PRODUCTS_API} from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";

export default function Process({ handleClose, triggerRefresh, invoice={} , getData}){
    const options = [
        { label: "Processed" , value: true },
        { label: "Not Processed" , value: false }
    ]
    const [processed, setProcessed] = useState(options[0])
    const [loading, setLoading] = useState(false)

    async function processInvoice(){
        const { token } = await getStorage()
        setLoading(true)

        const options = {
            method: "PUT",
            url: PRODUCTS_API + "/invoice/"+invoice.id,
            headers: {
                authorization: "Bearer " + token,
            },
            data: {
                is_processed: processed.value,
                processed_on:  new Date().toISOString()
            },
        }

        axios(options)
            .then((res) => {
                setLoading(false)
                if (res.data) {
                    toastMessage("success", "Invoice processed successfully.")
                    handleClose()
                    getData()
                    triggerRefresh(prev=> !prev)
                }
            })
            .catch((err) => {
                setLoading(false)
                if (!err.response) {
                    return toastMessage(
                        "error",
                        "Connection failed. This could either be due to network failure or the resource is currently unavailable"
                    )
                }
                toastMessage("error", err.response.data.message)
            })
    }
    return (
        <div className="card">
            <div className="card-body">
                <Select
                    label={"Status"}
                    value={processed}
                    options={options}
                    onChange={(e)=> setProcessed(e)}
                />

            </div>
            <div className="card-footer d-flex gap-3 justify-content-end">
                <Button
                    className="btn btn-secondary"
                    text="Close"
                    onPress={handleClose}
                />

                <Button
                    className="btn btn-primary"
                    text="Submit"
                    isSubmitting={loading}
                    onPress={processInvoice}
                />
            </div>
        </div>
    )
}