import React from "react";
import "./styles.css";
import Dropzone from "react-dropzone";
import { Spinner } from "react-bootstrap";

const FileUpload = (props) => {
  return (
    <>
      <Dropzone
        accept={props.accept}
        multiple={props.multiple}
        onDrop={(acceptedFiles, rejectedFiles) =>
          props.onDrop(acceptedFiles, rejectedFiles)
        }
      >
        {({ getRootProps, getInputProps }) => (
          <section className="file_uploader">
            <div
              {...getRootProps()}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input {...getInputProps()} />
              <p style={{ marginBottom: 0 }}>
                Drag 'n' drop or{" "}
                <b>
                  <u>Browse</u>
                </b>
              </p>
            </div>
          </section>
        )}
      </Dropzone>
      {props.error && <div className="text-danger">{props.error}</div>}
      <small className="text-disabled">
        Uploaded file should be in the following format {props.format}{" "}
        {props.size && "size limit " + props.size + " MB)"}
      </small>
      {props.onDownload &&( props.downloading ? <Spinner variant="success" animation="border" size="sm"/> : <a className="text-green download-button" onClick={props.onDownload}>. Download template.</a>)}
      {props.acceptedFiles && props.acceptedFiles.length > 0 && (
        <ul className="list-group mt-4 mb-4">
          {props.acceptedFiles.map((file, i) => {
            return (
              <li className="list-group-item file_list" key={i}>
                <div className="icon-con">
                  <i className="bx bxs-file"></i>
                </div>
                {props.fileName ? (
                  <span>{props.filename}</span>
                ) : (
                  <span>{file.name}</span>
                )}
                <div className="right_icon text-danger">
                  <i
                    onClick={() => props.delete(file)}
                    className="bx bx-trash"
                  ></i>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {props.rejectedFiles &&
        props.rejectedFiles.length > 0 &&
        props.rejectedFiles.map((file) => {
          return (
            file.errors && (
              <div className="text-danger">{file.errors[0].message}</div>
            )
          );
        })}
    </>
  );
};

export default FileUpload;
