import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Button } from '../../components/Button'
import { Input } from '../../components/Input'
import { Table } from '../../components/Table'
import { VEHICLES_API } from '../../constants/api'
import { getStorage } from '../../utils/storage'
import toastMessage from '../../utils/toastMessage'

const Bulbs = (props) => {
 const { handleClose, selectedVehicle } = props

 const defaultNewItem = {
     item: "",
     description: ""
 }

 const [pagination, setPagination] = useState({})
 const [data, setData] = useState([])
 const [loading, setLoading] = useState(false)
 const [adding, setAdding] = useState(false)
 const [deleting, setDeleting] = useState(false)
 const [newItem, setNewItem] = useState(defaultNewItem)
 const [error, setError] = useState({})

 useEffect(()=>{
     getData()
 }, [])


 const getData = async(params)=>{
     const { token } = await getStorage()
     setLoading(true)

     const options = {
         url: VEHICLES_API+"/bulbs/"+selectedVehicle.id,
         method: "GET",
         headers: { Authorization: `Bearer ${token}`},
         params: {
            ...params
         }
     }

     axios(options)
        .then(res=>{
            setLoading(false)
            if(res.data){
                const { pagination, results } = res.data
                setData(results)
                setPagination(pagination)
            }
        })
        .catch(err=>{
            setLoading(false)
        })

 }

 const handleInput = (e)=>{
     const { name, value } = e.target
     if(error[name]){
         delete error[name]
     }
     setNewItem(prev=>({
         ...prev,
         [name]: value
     }))
 }

 const validateNewItem = async()=>{
     let valid = true, err= {}
     const { item, description } = newItem

     if(!item || item.trim() === ""){
         valid = false
         err.item = "Item name is required"
     }

     if(!description || description.trim() === ""){
         valid = false
         err.description = "Item description is required"
     }

     await setError(err)

     return valid
 }

 const handleAdd = async()=>{
     const isValid = await validateNewItem()

     if(!isValid) return
     setAdding(true)
     const { token } = await getStorage()

     const options = {
         url: VEHICLES_API+"/bulbs",
         method: "POST",
         headers: { Authorization: `Bearer ${token}`},
         data: {
             ...newItem,
             vehicleId: selectedVehicle.id
         }
     }

     axios(options)
        .then(res=>{
            setAdding(false)
            if(res.status === 201){
              getData()
              setNewItem(defaultNewItem)
              return toastMessage(
                "success",
                "Bulb item added successfully."
              )
            }
        })
        .catch(err=>{
            setAdding(false)
            if(!err.response || !(err.response&&err.response.data)){
              return toastMessage(
                "error",
                "Connection failed. This could either be due to network failure or the resource is currently unavailable"
              )
            }
            toastMessage("error", err.response.data.message)
        })
 }

 const handleDelete = async(selected)=>{
     setDeleting(true)
     const { token } = await getStorage()

     const options = {
         url: VEHICLES_API+"/bulb/"+selected.id,
         method: "DELETE",
         headers: { Authorization: `Bearer ${token}`},
    }

    axios(options)
     .then(res=>{
         setDeleting(false)
         if(res.status === 200){
           toastMessage("success", "Bulb item deleted")
           getData()
         }
     })
     .catch((err)=>{
         setDeleting(false)
         if(!err.response){
           return toastMessage(
             "error",
             "Connection failed. This could either be due to network failure or the resource is currently unavailable"
           )
         }
         toastMessage("error", err.response.data.message)
     })
 }

 const handlePgination = (page)=>{
     if(!page) return
     getData({ pageNumber: page})
 }


 const headers = [
     { title: "Item", key: "item" },
     { title: "Description", key: "description" },
     { title: "Created At", key: "createdAt", isMoment: true, formatTime: "lll" },
     { title: "Action", key: "action" }
 ]

 return (
     <>
     <div className="modal-body">
         <div className="card mb-2" style={{ padding: 0 }}>
             <div className="card-header">
                 <h1>Add Bulb</h1>
             </div>
             <div className="card-body row" style={{display: "flex", alignItems: 'flex-end'}}>
                 <div className="col-md-5">
                     <Input 
                         label="Item: "
                         required
                         name="item"
                         value={newItem.item}
                         onChange={handleInput}
                         error={error.item}
                     />
                 </div>
                 <div className="col-md-5">
                     <Input 
                         label="Description: "
                         required
                         name="description"
                         value={newItem.description}
                         onChange={handleInput}
                         error={error.description}
                     />
                 </div>
                 <div className="col-md-2">
                     <Button 
                         className="btn-primary mb-3"
                         text="Add"
                         onPress={handleAdd}
                         isSubmitting={adding}
                     />
                 </div>
             </div>

            
         </div>
     </div>

     <div className="card">
         <div className="card-body">
             <Table 
                 isLoading={loading}
                 hideHeader
                 headers={headers}
                 data={data}
                 pagination={pagination}
                 handlePgination={handlePgination}
                 actions={[
                     {
                         name: deleting ?
                         <Spinner animation='border' variant='danger' size='sm' /> 
                         :"Delete",
                         onPress: (row)=> handleDelete(row),
                     },
                 ]}
             />
         </div>
     </div>
       
     <div className="modal-footer d-flex gap-3">
       <Button  
         text="Cancel" 
         className="btn-secondary" 
         onPress={()=> handleClose(false)}
      />
     </div>
   </>
   )
}
export default Bulbs