function convertToCSV(arr = []) {
	const separator = ","
	let csvHeader = ""
	let csvRows = ""

	if (arr.length === 0) {
		return null
	}
	const keys = Object.keys(arr[0])
	csvHeader = keys.join(separator) + "\n"

	for (const item of arr) {
		const values = keys.map((key) => {
			let value = item[key]

			if (typeof value === "string") {
				value = `"${value}"`
			}
			return value
		})
		csvRows += values.join(separator) + "\n"
	}
	const csv = csvHeader + csvRows
	const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
	const url = URL.createObjectURL(blob)

	return url
}

export default convertToCSV
