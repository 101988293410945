import {LoadingSpinner} from "../components/LoadingSpinner";
import useApp from "../hooks/useApp";
import {useEffect} from "react";

export default function LandingPage() {
    const {menus} = useApp()
    useEffect(() => {
        if (menus && menus.length > 0) {
            //Check the first menu path and redirect to there
            const menu = menus[0]
            if (menu && menu.menus && menu.menus.length > 0) {
                window.location.href = menu.menus[0].route

            }
        }
    }, [menus]);
    return (
        <div className="mh-100 min-vh-75 w-100 d-flex justify-content-center align-items-center">
            <LoadingSpinner/>
        </div>
    )
}