import {getStorage} from "../../utils/storage";
import {USERS_API} from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import {useEffect, useState} from "react";
import {Button} from "../../components/Button";
import {Table} from "../../components/Table";
import {Spinner} from "react-bootstrap";

export default function Permissions({ user, handleClose, setActive, active }){
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [revoking, setRevoking] = useState(false)

    useEffect(() => {
        if(user.id){
            getData()
        } else {
            setLoading(false)
        }
    }, [active]);

    async function getData(){
        const { token } = await getStorage()
        const options= {
            url: `${USERS_API}/permission/${user.id}`,
            method: "GET",
            headers: {
                authorization: "Bearer " + token,
            }
        }
        await axios(options)
            .then(res=>{
                if(res.data){
                    setData(res.data)
                }
            })
            .catch(err=>{
                if(err.response&&err.response.data&&err.response.data.message){
                    toastMessage("error", err.response.data.message)
                } else {
                    toastMessage("error", "Failed! Please try again.")
                }
            })
        setLoading(false)
    }
    async function revoke(perm){
        const { token } = await getStorage()
        const options= {
            url: `${USERS_API}/permission/${perm.id}`,
            method: "DELETE",
            headers: {
                authorization: "Bearer " + token,
            }
        }
        setRevoking(true)

        await axios(options)
            .then(res=>{
                if(res.data){
                    toastMessage("success", "Permission revoked successfully.")
                    getData()
                }
            })
            .catch(err=>{
                if(err.response&&err.response.data&&err.response.data.message){
                    toastMessage("error", err.response.data.message)
                } else {
                    toastMessage("error", "Failed! Please try again.")
                }
            })
        setRevoking(false)
    }

    return (
        <div className="card">
            <div className="card-body">
                <Table
                    noSearch={true}
                    showAdd={true}
                    addButtonText={"Add Permission"}
                    handleAddPressed={()=>{
                        setActive("add")
                        setTimeout(()=> setActive(null), 500)
                    }}
                    data={data}
                    pagination={{}}
                    headers={[
                        { title: "Name", key: "label" },
                        { title: "Permission", key: "value" },
                        { title: "Date Created", key: "created_at", isMoment: true, formatTime: "lll"},
                        { title: "Action", key: "action" }
                    ]}
                    isLoading={loading}
                    actions={[
                        {
                            name: revoking?(
                                <div className="d-flex justify-content-center py-1">
                                    <Spinner animation={"border"} variant={"danger"} size={"sm"} />
                                </div>
                            ): "Revoke",
                            onPress: (row)=> revoke(row),
                        }
                    ]}
                />
            </div>

            <div className="card-footer d-flex gap-3 justify-content-end">
                <Button className={"btn-secondary"} text={"Cancel"} onPress={handleClose}/>
            </div>
        </div>
    )
}