import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {DeleteModal, Modal} from '../components/Modal'
import {Table} from '../components/Table'
import {VEHICLES_API} from '../constants/api'
import VehicleDetails from '../features/Vehicles/VehicleDetails'
import {getStorage} from '../utils/storage'
import toastMessage from '../utils/toastMessage'
import useApp from "../hooks/useApp";

const VehiclesPage = () => {

    const [vehicles, setVehicles] = useState([])
    const [pagination, setPagination] = useState({})
    const [loading, setLoading] = useState(true) //true by default
    const [deleteModal, setDeleteModal] = useState(false)
    const [viewModal, setViewModal] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [selected, setSelected] = useState(null)
    const [search, setSearch] = useState("")
    const {permissions} = useApp()


    useEffect(() => {
        getVehicles()
    }, [])


    useEffect(() => {
        const getData = setTimeout(() => {
            onSearch()
        }, 1000)
        return () => clearTimeout(getData)
    }, [search])


    const getVehicles = async (params) => {
        setLoading(true)
        const {token,id, permissions} = await getStorage()
        let perms = []
        if (permissions) {
          perms = permissions.map((perm) => perm.value)
        }
        let url = VEHICLES_API
        if (perms.includes("view-added-vehicles")) {
          url += `/vehicles-by-user?userId=${id}`
        }
        if (perms.includes("view-vehicles")) {
          url += "/vehicles"
        }

        const options = {
            url,
            method: "GET",
            headers: {authorization: `Bearer ${token}`},
            params: {
                ...params
            }
        }

        axios(options)
            .then(res => {
                setLoading(false)
                if (res.data) {
                    const {pagination, results} = res.data

                    setVehicles(results)
                    setPagination(pagination)
                }
            })
            .catch(err => {
                setLoading(false)
                if (!err.response) {
                    return toastMessage(
                        "error",
                        "Connection failed. This could either be due to network failure or the resource is currently unavailable"
                    )
                }
                toastMessage("error", err.response.data.message)
            })
    } //GET ALL VEHICLES

    const handlePagination = (page) => {
        if (!page) return
        getVehicles({pageNumber: page})

    }

    const handleView = async (vehicle) => {
        await setSelected(vehicle)
        setViewModal(true)
    }

    const handleDelete = (vehicle) => {
        setSelected(vehicle)
        setDeleteModal(true)
    }

    const handleCloseModal = () => {
        setDeleteModal(false)
        setViewModal(false)
        setSelected(null)
    }

    const handleSearch = (e) => {
        const {value} = e.target
        setSearch(value)
        if (value === "") {
            return getVehicles()
        }
    }

    function onSearch() {
        if (!search || search === "") return
        getVehicles({search: search.toUpperCase().trim()})
    }


    const onDelete = async () => {
        const {token} = await getStorage()
        setDeleting(true)

        const options = {
            method: "DELETE",
            url: VEHICLES_API + "/vehicle/" + selected.id,
            headers: {
                authorization: "Bearer " + token,
            }
        };

        axios(options)
            .then(res => {
                setDeleting(false)
                if (res.status === 200) {
                    toastMessage("success", "Vehicle deleted successfully")
                    handleCloseModal()
                    getVehicles()
                }

            })
            .catch((err) => {
                setDeleting(false)
                if (!err.response) {
                    return toastMessage(
                        "error",
                        "Connection failed. This could either be due to network failure or the resource is currently unavailable"
                    )
                }
                toastMessage("error", err.response.data.message)
            })
    }


    const headers = [
        {title: "Registration", key: "registration"},
        {title: "Make", key: "make"},
        {title: "Model", key: "model"},
        // { title: "Year", key: "year" },
        // { title: "Engine Capacity", key: "capacity" },
        {title: "Client FirstName", key: "firstName"},
        {title: "Client LastName", key: "lastName"},
        {title: "Company Name", key: "companyName"},
        {title: "Client Phone", key: "phone"},
        {title: "Date of Registration", key: "createdAt", isMoment: true, formatTime: "lll"},
        // { title: "Action", key: "action"}
    ]
    if (permissions && permissions.includes("manage-vehicles")) {
        headers.push({title: "Action", key: "action"})
    }
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <Table
                        headers={headers}
                        data={vehicles}
                        search_text={search}
                        handleSearch={handleSearch}
                        isLoading={loading}
                        addButtonText="Add Vehicle"
                        placeholder={"Search by registration or client/company name"}
                        pagination={pagination}
                        handlePagination={handlePagination}
                        // rowPress={handleView}
                        actions={[
                            {
                                name: "View",
                                onPress: (row) => handleView(row),
                            },
                            // {
                            //  name: "Edit",
                            //  onPress: (row)=> handleEdit(row),
                            // },
                            {
                                name: "Delete",
                                onPress: (row) => handleDelete(row),
                            },
                        ]}
                    />
                </div>
            </div>
            <Modal
                show={viewModal}
                title={"Vehicle Management"} //+ selected ? `: ${selected.firstName} ${selected.lastName}`: ''}
                handleClose={handleCloseModal}
                size='lg'
            >
                <VehicleDetails
                    selectedVehicle={selected}
                    setSelected={setSelected}
                    getVehicles={getVehicles}
                    handleClose={handleCloseModal}
                />
            </Modal>
            <Modal
                show={deleteModal}
                title={`Delete Vehicle`}
                handleClose={handleCloseModal}
            >
                <DeleteModal
                    name={selected && (selected.registration)}
                    handleClose={handleCloseModal}
                    isSubmitting={deleting}
                    onDelete={onDelete}
                />
            </Modal>
        </>
    )
}

export default VehiclesPage