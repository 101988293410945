import { useContext } from "react"

import AppContext from "../context/AppContext"

const useApp = () => {
	const doc = useContext(AppContext)

	return doc
}

export default useApp
