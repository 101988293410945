import axios from "axios"
import React, { useEffect, useState } from "react"
import { Button } from "../../components/Button"
import { Table } from "../../components/Table"
import { PRODUCTS_API } from "../../constants/api"
import { getStorage } from "../../utils/storage"
import toastMessage from "../../utils/toastMessage"

const Payments = ({ invoice, handleClose }) => {
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getData()
  }, [])
  const getData = async (params = {}) => {
    const { token } = await getStorage()
    setLoading(true)

    const options = {
      method: "GET",
      url: PRODUCTS_API + "/payment/" + invoice.id,
      headers: {
        authorization: "Bearer " + token,
      },
      params: {
        ...params,
      },
    }

    axios(options)
      .then((res) => {
        setLoading(false)
        if(res.data){
          setData(res.data)
        }
      })
      .catch((err) => {
        setLoading(false)
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }
  const headers = [
    { title: "Mode of Payment", key: "payment_mode" },
    { title: "Amount Paid", key: "amount_paid" },
    { title: "Is Paid", key: "is_paid" },
    {
      title: "Created At",
      key: "created_at",
      isMoment: true,
      formatTime: "lll",
    },
    // { title: "Action", key: "action"}
  ]
  return (
    <>
      <div className="card-body">
        <Table
          pagination={pagination}
          isLoading={loading}
          data={data}
          headers={headers}
        />
      </div>
      <div className="card-footer">
        <Button
          className="btn btn-secondary"
          text="Close"
          onPress={() => handleClose(false)}
        />
      </div>
    </>
  )
}

export default Payments
