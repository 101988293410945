import {USERS_API} from "../constants/api";
import axios from "axios";
import toastMessage from "../utils/toastMessage";
import {useState} from "react";
import {Input} from "../components/Input";
import {Button} from "../components/Button";
import {Link} from "react-router-dom";

export default function ResetPassword(){
    const [sending, setSending] = useState(false)
    const [isFound, setIsFound] = useState(false)
    const [error, setError] = useState({})
    const [email, setEmail]= useState("")

    async function validate(){
        let valid = true
        let err = {}
        if(!email){
            err.email = "Email is required."
            valid = false
        }
        setError(err)
        return valid
    }

    async function submit(){
        const isValid = await validate()
        if(!isValid) return
        const options = {
            url: `${USERS_API}/reset-password`,
            method: "GET",
            params:{
                email
            }
        }
        setSending(true)
        axios(options)
            .then(res=>{
                if(res.data){
                    setSending(false)
                    setIsFound(true)
                    toastMessage("success", "Password reset instructions have been sent to your email.")
                }
            })
            .catch(err=>{
                setSending(false)
                if(!err.response){
                    return toastMessage(
                        "error",
                        "Connection failed. This could either be due to network failure or the resource is currently unavailable"
                    )
                }
                toastMessage("error", err.response.data.message)
            })
    }


    return (
        <div className="form-container">
            <div className="col-md-4">
                {isFound ? (
                    <div className="card">
                        <div className="card-body">
                            <center>
                                <div className="success-found-icon">
                                    <i className="bx bx-check"></i>
                                </div>
                                <p>
                                    Instruction to reset your password has been sent to:
                                    <br />
                                    <b>{email}</b>
                                </p>
                            </center>
                            <Button
                                type={"link"}
                                path="/login"
                                className=" btn-primary w-100"
                                text="Back to Login"
                            />
                        </div>
                    </div>
                ) : (
                    <div className="card">
                        <div className="card-header">
                            <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }} className={"d-flex flex-column gap-3"}>
                                <b className={"text-lg"}>Forgot your Password?</b>
                                <small className="text-disabled">
                                    Enter your email address, We'll send you an email with instruction
                                    to reset your password
                                </small>
                            </div>
                        </div>
                        <div className="card-body">
                            <form className="needs-validation" noValidate>
                                <Input
                                    label="Email:"
                                    value={email}
                                    onChange={(e) => (
                                        setEmail(e.target.value), delete error.email
                                    )}
                                    placeholder="Enter your email address"
                                    error={error.email}
                                    required

                                />
                                <Button
                                    isSubmitting={sending}
                                    onPress={submit}
                                    className=" btn-primary w-100"
                                    text="Reset Password"
                                />
                                <Button
                                    link="/login"
                                    type={"link"}
                                    className=" btn-link w-100"
                                    text="Back to Login"
                                />
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}