import React, { useState, useEffect } from "react"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { CLIENTS_API } from "../../constants/api"
import { countries } from "../../constants/countries"
import axios from "axios"
import { getStorage } from "../../utils/storage"
import toastMessage from "../../utils/toastMessage"

const Client = (props) => {
  const { edit, client, handleClose, getClients, setSelected } = props

  const defualtUser = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    location: "",
    selected_country: countries[0],
    companyName: "",
  }

  const [user, setUser] = useState({ ...defualtUser })

  const [error, setError] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    if (client) {
      setUser((prev) => ({
        ...prev,
        ...client,
      }))
    }
    if (edit || !client) {
      setDisabled(false)
    }
  }, [])

  const cancel = () => {
    handleClose(false)
  }

  const validateClient = async () => {
    const {
      firstName,
      lastName,
      email,
      address,
      location,
      phone,
      companyName,
    } = user

    const err = {}

    let isValid = true

    if (!firstName || firstName === "") {
      err.firstName = "First Name is required"
      isValid = false
    }

    if (!lastName || lastName === "") {
      err.lastName = "Last Name is required"
      isValid = false
    }

    if (!email || email === "") {
      err.email = "Email address is required"
      isValid = false
    }

    if (!address || address === "") {
      err.address = "Client Address is required"
      isValid = false
    }

    if (!location || location === "") {
      err.location = "Client Location is required"
      isValid = false
    }

    if (!phone || phone === "") {
      err.phone = "Phone Number is required"
      isValid = false
    }

    if (phone && phone.length < 9) {
      err.phone = "A valid Phone Number is required"
      isValid = false
    }

    if (!companyName || companyName === "") {
      err.companyName = "Client Company Name is required"
      isValid = false
    }

    setError(err)

    return isValid
  }

  const handleSubmit = async () => {
    const valid = await validateClient()
    if (!valid) return

    setSubmitting(true)

    const { token, id } = await getStorage()
    let userIdData = {}
    let method = edit && edit ? "PUT" : "POST"
    let path = edit && edit ? `/client/${client && client.id}` : "/client"
    if (!edit) {
      userIdData = {
        userId: id,
      }
    }

    const {
      firstName,
      lastName,
      email,
      address,
      location,
      phone,
      companyName,
      selected_country,
    } = user

    let phoneNumber = phone

    if (phoneNumber.length < 12) {
      phoneNumber = selected_country && selected_country.value + phone
    }

    if (phone.length > 9 && phone[0] == "0") {
      phoneNumber = selected_country.value + phone.slice(1, 10)
    }

    const options = {
      method,
      url: CLIENTS_API + path,
      headers: {
        authorization: "Bearer " + token,
      },
      data: {
        ...userIdData,
        firstName,
        lastName,
        email,
        address,
        location,
        phone: phoneNumber,
        companyName,
      },
    }

    axios(options)
      .then((res) => {
        setSubmitting(false)
        if (res.status === 201) {
          getClients()
          console.log("Added clei: ", res)
          setSelected(res.data)
          return toastMessage("success", "Client added successfully.")
        }

        if (res.status === 200) {
          getClients()
          return toastMessage("success", "Client updated successfully.")
        }
      })
      .catch((err) => {
        setSubmitting(false)
        console.log("Added error: ", err)

        if (!err.response || !(err.response && err.response.data)) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }

  const handleInput = (e) => {
    const { name, value } = e.target

    if (error[name]) {
      delete error[name]
    }
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSelect = (name, value) => {}

  return (
    <div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-6">
            <Input
              name="firstName"
              error={error.firstName}
              onChange={handleInput}
              type="text"
              required
              disabled={disabled}
              value={user.firstName}
              label="First Name:"
            />
          </div>

          <div className="col-md-6">
            <Input
              name="lastName"
              error={error.lastName}
              onChange={handleInput}
              disabled={disabled}
              type="text"
              required
              value={user.lastName}
              label="Last Name:"
            />
          </div>

          <Input
            name="companyName"
            error={error.companyName}
            onChange={handleInput}
            type="text"
            required
            disabled={disabled}
            value={user.companyName}
            label="Company:"
          />

          <div className="col-md-6">
            <Input
              name="address"
              error={error.address}
              onChange={handleInput}
              type="text"
              required
              disabled={disabled}
              value={user.address}
              label="Address:"
            />
          </div>

          <div className="col-md-6">
            <Input
              name="location"
              error={error.location}
              onChange={handleInput}
              type="text"
              required
              disabled={disabled}
              value={user.location}
              label="Location:"
            />
          </div>

          <div className="col-md-6">
            <Input
              name="email"
              error={error.email}
              onChange={handleInput}
              type="email"
              required
              disabled={disabled}
              value={user.email}
              label="Email:"
            />
          </div>

          <div className="col-md-6">
            <Input
              label="Phone Number:"
              name="phone"
              value={user.phone}
              onChange={handleInput}
              disabled={disabled}
              error={error.phone}
              required
              isPhone
              // type={'number'}
              maxLength={10}
              minLength={9}
              countries={countries}
              countryValue={user.selected_country}
              countryOnchange={(e) => handleSelect("selected_country", e)}
              type="telephone"
            />
          </div>
        </div>
      </div>
      <div className="modal-footer d-flex gap-3">
        <Button text="Cancel" className="btn-secondary" onPress={cancel} />
        <Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          text={edit ? "Update" : "Submit"}
          disabled={disabled}
          className="btn-primary"
        />
      </div>
    </div>
  )
}

export default Client
