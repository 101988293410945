import React from "react";
import { Logo } from "../Logo";
import "./styles.css";

const Splash = () => (
  <div className="splash-container">
    <Logo containerWidth={200} />
  </div>
);

export default Splash;
