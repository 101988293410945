import axios from "axios"
import {useEffect, useState} from "react"
import {Modal} from "../../components/Modal"
import {Table} from "../../components/Table"
import {PRODUCTS_API} from "../../constants/api"
import {getStorage} from "../../utils/storage"
import toastMessage from "../../utils/toastMessage"
import Invoice from "./Invoice"
import useApp from "../../hooks/useApp";

const Processed = ({refresh}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [pagination, setPagination] = useState({})
    const [search, setSearch] = useState("")
    const [modal, setModal] = useState(false)
    const [selected, setSelected] = useState()
    const {permissions} = useApp()

    useEffect(() => {
        getData()
    }, [refresh])

    useEffect(() => {
        const getData = setTimeout(() => {
            getSearch()
        }, 1000)
        return () => clearTimeout(getData)
    }, [search])

    const getData = async (params = {}) => {
        const {token} = await getStorage()
        setLoading(true)

        const options = {
            method: "GET",
            url: PRODUCTS_API + "/invoices",
            headers: {
                authorization: "Bearer " + token,
            },
            params: {
                is_processed: true,
                ...params,
            },
        }

        axios(options)
            .then((res) => {
                setLoading(false)
                if (res.data) {
                    const {pagination, results} = res.data

                    setData(results)
                    setPagination(pagination)
                }
            })
            .catch((err) => {
                setLoading(false)
                if (!err.response) {
                    return toastMessage(
                        "error",
                        "Connection failed. This could either be due to network failure or the resource is currently unavailable"
                    )
                }
                toastMessage("error", err.response.data.message)
            })
    }

    const handlePagination = (page) => {
        if (!page) return
        getData({pageNumber: page})
    }

    function getSearch() {
        if (search === "") return
        return getData({search})
    }

    const handleSearch = (e) => {
        const {value} = e.target
        setSearch(value)
        if (value === "") {
            return getData()
        }
    }

    const handleView = async (client) => {
        await setSelected(client)
        setModal(true)
    }

    const headers = [
        {
            title: "Invoice Number",
            key: "invoice_no",
        },
        {
            title: "Invoice Amount",
            key: "amount",
        },
        {
            title: "Amount Paid",
            key: "amount_paid",
        },
        {
            title: "Balance",
            key: "balance",
        },
        {
            title: "Processed",
            key: "is_processed",
        },
        {
            title: "Order Complete",
            key: "is_complete",
        },
        {
            title: "Created At",
            key: "createdAt",
            isMoment: true,
            formatTime: "lll",
        },
        {
            title: "Processed On",
            key: "processed_on",
            isMoment: true,
            formatTime: "lll",
        },
        // { title: "Action", key: "action" },
    ]
    if (permissions && permissions.includes("manage-sales")) {
        headers.push({title: "Action", key: "action"})
    }

    const handleClose = () => {
        setModal(false)
        setSelected(null)
    }
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <Table
                        isLoading={loading}
                        data={data}
                        pagination={pagination}
                        handlePagination={handlePagination}
                        search_text={search}
                        handleSearch={handleSearch}
                        placeholder={"Search by invoice no"}
                        headers={headers}
                        rowPress={(row) => handleView(row)}
                        actions={[
                            {
                                name: "View",
                                onPress: (row) => handleView(row),
                            },
                        ]}
                    />
                </div>
            </div>
            <Modal
                show={modal}
                title={"View Invoice"}
                size="lg"
                handleClose={handleClose}>
                <Invoice handleClose={handleClose} invoice={selected}/>
            </Modal>
        </>
    )
}

export default Processed
