import axios from "axios"
import React, { useEffect, useState } from "react"
import { PRODUCTS_API } from "../../constants/api"
import { getStorage } from "../../utils/storage"
import toastMessage from "../../utils/toastMessage"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"

const Customer = ({ invoice, handleClose }) => {
  const [user, setUser] = useState({})
  useEffect(() => {
    getData()
  }, [])
  const getData = async (params = {}) => {
    const { token } = await getStorage()
    const options = {
      method: "GET",
      url: PRODUCTS_API + "/invoice-details/" + invoice.id,
      headers: {
        authorization: "Bearer " + token,
      },
      params: {
        ...params,
      },
    }

    axios(options)
      .then((res) => {
        if (res.data) {
          setUser(res.data)
        }
        console.log("Customer Res: ", res)
      })
      .catch((err) => {
        if (!err.response) {
          return toastMessage(
            "error",
            "Connection failed. This could either be due to network failure or the resource is currently unavailable"
          )
        }
        toastMessage("error", err.response.data.message)
      })
  }
  return (
    <>
      <div className="card-body">
        <form action="" className="row">
          <div className="col-md-6">
            <Input label="Name" value={user.user_name} disabled />
          </div>
          <div className="col-md-6">
            <Input label="Location" value={user.user_location} disabled />
          </div>
          <Input label="Address" value={user.user_address} disabled />
          <div className="col-md-6">
            <Input label="Email" value={user.user_email} disabled />
          </div>
          <div className="col-md-6">
            <Input label="Phone" value={user.user_phone} disabled />
          </div>
        </form>
      </div>
      <div className="card-footer">
        <Button
          className="btn btn-secondary"
          text="Close"
          onPress={() => handleClose(false)}
        />
      </div>
    </>
  )
}

export default Customer
