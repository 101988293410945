import React from 'react'
import { Container } from '../components/Container';
import { Login } from '../components/Login';


const LoginPage = () => {
    return (
      <Container>
        <Login />
      </Container>
    );
}

export default LoginPage