import {Button} from "../../components/Button";
import {useState} from "react";
import {Input, TextArea} from "../../components/Input";
import {PRODUCTS_API} from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import {getStorage} from "../../utils/storage";

export default function AddBanner({handleClose, getData}){
    const [loading, setLoading] = useState(false)
    const [banner, setBanner] = useState({
        name: "",
        description: "",
        banner: ""
    })
    const [error, setError] = useState({})

    const handleInput=(e={})=>{
        const {name,value} = e.target
        if(error[name]) delete error[name]
        setBanner(prev=>({...prev, [name]: value }))
    }

    const handleFile=(e={})=>{
        const {name,files} = e.target
        if(error[name]) delete error[name]
        const file = files[0]
        if(file){
            const {size, type} = file
            if(size>3145728){
                setError(prev=>({...prev, banner: "Size is too large. Maximum of 3MB is required."}))
            } else {
                setBanner(prev=>({...prev, [name]: file }))
            }
        }
    }

    async function validate(){
        const {name, banner:image } = banner
        let err = {}
        let valid = true
        if(!name||name===""){
            valid=false
            err.name="Banner name is required."
        }
        if(!image||image===""){
            valid=false
            err.banner="Banner image is required."
        }
        setError(prev=>({...prev, ...err}))
        return valid
    }

    async function handleSubmit(e){
        e&&e.preventDefault()
        const isValid = await validate()
        if(!isValid) return
        const { token } = await getStorage()
        const {name, banner:image, description } = banner

        const formData = new FormData()
        formData.set("name", name)
        formData.set("banner", image)
        formData.set("description", description?description:" ")

        setLoading(true)
        const options = {
          method: "POST",
          url: PRODUCTS_API + "/banners",
            data: formData,
          headers: {
            authorization: "Bearer " + token,
          }
        };

        axios(options)
            .then(res=>{
              setLoading(false)
              if(res.data){
                toastMessage("success", "Banner uploaded successfully")
                  handleClose()
                  getData()
              }
            })
            .catch((err)=>{
              setLoading(false)
              if(!err.response){
                return toastMessage(
                  "error",
                  "Connection failed. This could either be due to network failure or the resource is currently unavailable"
                )
              }
              toastMessage("error", err.response.data.message)
            })

    }
    return(
        <div className="card">
            <div className="card-body">
                <Input label={"Name"} required name={"name"} value={banner.name} error={error.name} onChange={handleInput} />
                <Input accept="image/png, image/gif, image/jpeg" label={"Banner"} required name={"banner"} type={"file"} error={error.banner} onChange={handleFile} />
                <TextArea label={"Description"} name={"description"} value={banner.description} onChange={handleInput} />
            </div>
            <div className="card-footer d-flex justify-content-end gap-3">
                <Button className={"btn-secondary"} onPress={handleClose} text={"Cancel"} />
                <Button className={"btn-primary"} onPress={handleSubmit} text={"Upload"} isSubmitting={loading} />
            </div>
        </div>
    )
}