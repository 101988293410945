import {Button} from "../../components/Button";
import {Select} from "../../components/Input";
import {useState} from "react";
import permissions from "../../constants/permissions";
import {USERS_API} from "../../constants/api";
import {getStorage} from "../../utils/storage";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";

export default function AddPermission({ user, handleClose, setActive }){
    const [perm, setPerm] = useState()
    const [submitting, setSubmitting] = useState(false)

    async function submit(){
        if(!perm.label||!perm.value||!user) return
        const { token } = await getStorage()
        const options= {
            url: `${USERS_API}/permission`,
            method: "POST",
            headers: {
                authorization: "Bearer " + token,
            },
            data:{
                user_id: user.id,
                label: perm.label,
                value: perm.value
            }
        }
        setSubmitting(true)
        await axios(options)
            .then(res=>{
               if(res.data){
                   toastMessage("success", "Permission added successfully")
                   setActive("permissions")
                   setTimeout(()=> setActive(null), 500)
               }
            })
            .catch(err=>{
                if(err.response&&err.response.data&&err.response.data.message){
                    toastMessage("error", err.response.data.message)
                } else {
                    toastMessage("error", "Failed! Refresh the page to try again.")
                }
            })
        setSubmitting(false)
    }

    return (
        <div className="card">
            <div className="card-body">
                <Select
                    required
                    label={"Permission"}
                    options={permissions}
                    value={perm}
                    onChange={(val)=> setPerm(val)}
                />
            </div>
            <div className="card-footer d-flex gap-3 justify-content-end">
                <Button className={"btn-secondary"} text={"Cancel"} onPress={handleClose}/>
                <Button
                    className={"btn-primary"}
                    text={"Submit"}
                    onPress={submit}
                    isSubmitting={submitting}
                />
            </div>
        </div>
    )
}