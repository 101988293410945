import React, { useEffect, useState } from 'react'
import { Settings } from '../features/Settings'
import { getStorage } from '../utils/storage'

const SettingsPage = () => {

  return (
    <div className="row">
     <Settings />
    </div>
  )
}

export default SettingsPage