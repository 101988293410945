import React from "react";
import { Link } from "react-router-dom";
import { menus } from "../../constants/sidebar-menus";
import { Logo } from "../Logo";
import "./styles.css";

const Sidebar = ({ menus=[], signOut, pathname, onToggle, hideSidebarOnMobile }) => (
  <div id="sidebar" className={`sidebar`}>
    <div className="header mb-3 d-flex flex-column justify-content-start align-items-center">
      <div className="logo">
        <Logo containerWidth={150} />
      </div>
      <span className="name ">PartsFinders Club</span>
      <div className="btn-sm toggle" onClick={onToggle}>
        <i className={"bx bx-x"}></i>
      </div>
    </div>
    <div className="middle">
      <ul>
        {menus.map((menu, m) => {
          return (
            <li key={m}>
              {menu.title && menu.title !== "" && <h1>{menu.title}</h1>}

              {menu.menus.map((item, i) => {
                return (
                  <Link
                    key={i}
                    to={item.route}
                    title={item.title}
                    className={pathname === item.route ? "active" : ""}
                    onClick={hideSidebarOnMobile}
                  >
                    <div className="s-li-icon">
                      <i className={item.icon}></i>
                    </div>
                    <span>{item.title}</span>
                  </Link>
                );
              })}
            </li>
          );
        })}
      </ul>
    </div>
    <div className="footer">
      <ul className="sidebar-menu-bottom">
        <li>
          <Link
            to="#"
            onClick={signOut}
            title="Logout"
            className="text-danger"
            style={{ opacity: 1 }}
          >
            <div className="s-li-icon">
              <i className="bx bxs-log-out"></i>
            </div>
            <span>Logout</span>
          </Link>
        </li>
      </ul>
    </div>
  </div>
);

export default Sidebar;
