import React from "react"
import { Container } from "../components/Container"
import { Sidebar } from "../components/Sidebar"
import { Navbar } from "../components/Navbar"
import { clearStorage, getStorage } from "../utils/storage"
import appTitle from "../utils/appTitle"
import userLoggedInTimeout from "../utils/userLoggedInTimeout"
import checkPermissions from "../utils/checkPermissions"

class HomePage extends React.Component {
  state = {
    user: {},
    menus: [],
    permissions: [],
    isSidebarFullVisible: true,
  }

  componentDidMount = async () => {
    await this.setUserLoggedIn()
    const pathname =
      this.props &&
      this.props.match.location &&
      this.props.match.location.pathname
    document.title = appTitle(pathname)
    // await this.getAccount(this.state.user.token);
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.match &&
      this.props.match &&
      this.props.match.location &&
      nextProps.match.location &&
      this.props.match.location.pathname !== nextProps.match.location.pathname
    ) {
      document.title = appTitle(this.props.match.location.pathname)
      checkPermissions(this.state.permissions, this.props.location)
    }
  }

  setUserLoggedIn = async () => {
    let user = await getStorage()

    if (!user.token) return (window.location.href = "/login")

    const isSessionExpired = userLoggedInTimeout(user)

    if (isSessionExpired) {
      return (window.location.href = "/login")
    }
    const permissions = user.permissions.map((item) => item.value)
    const menus = await checkPermissions(permissions, this.props.location)

    await this.setState({
      user,
      menus,
      permissions,
    })
  }

  onToggle() {
    const sidebar_class = document.getElementById("sidebar").classList
    if (sidebar_class.contains("show")) {
      sidebar_class.remove("show")
      sidebar_class.add("hide")

      return
    }

    sidebar_class.add("show")
    sidebar_class.remove("hide")
  }

  hideSidebarOnMobile() {
    const screenWidth = window.screen.availWidth
    const sidebar_class = document.getElementById("sidebar").classList
    if (screenWidth < 600 && sidebar_class.contains("show")) {
      sidebar_class.remove("show")
      sidebar_class.add("hide")

      return
    }
  }

  onSignMeOut = async () => {
    await clearStorage()

    window.location.href = "/login"
  }

  render() {
    const pathname =
      this.props &&
      this.props.match.location &&
      this.props.match.location.pathname
    const title = appTitle(pathname)

    if (!this.state.user.token) {
      return null
    }

    return (
      <Container menus={this.state.menus} permissions={this.state.permissions}>
        <div className="app-home-container">
          <Sidebar
            menus={this.state.menus}
            onToggle={this.onToggle.bind(this)}
            signOut={this.onSignMeOut.bind(this)}
            pathname={
              this.props &&
              this.props.match.location &&
              this.props.match.location.pathname
            }
            hideSidebarOnMobile={this.hideSidebarOnMobile.bind(this)}
          />
          <div className="app-main-container">
            <Navbar
              user={this.state.user}
              onToggle={this.onToggle.bind(this)}
              onSignMeOut={this.onSignMeOut.bind(this)}
              title={title}
            />
            <div id="app-main-content" className="app-main-content">
              <div
                style={{
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default HomePage
