import axios from "axios";
import React from "react";
import toastMessage from "../../utils/toastMessage";
import { Button } from "../Button";
import { Input } from "../Input";
import { Logo } from "../Logo";
import "./styles.css";
import { clearStorage, getStorage, setStorage } from "../../utils/storage";
import userLoggedInTimeout from "../../utils/userLoggedInTimeout";
import { AUTH_API } from "../../constants/api";
import decode from "jwt-decode";

class Login extends React.Component {
  state = {
    username: "",
    password: "",
    error: {},
    isSubmitting: false,
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    const isExpired = await userLoggedInTimeout(user);

    if (isExpired && user.username && user.username !== "") {
      this.setState({ user, username: user.username });
    }
  };

  setUserLoggedIn = async () => {
    let user = await getStorage();

    if (!user.token && !user.accountVerified)
      return (window.location.href = "/login");

    if (user.iat <= new Date().getTime() / 1000) {
      user.token = undefined;
      user.accountVerified = false;

      window.location.href = "/login";
      return;
    }

    this.setState({
      user,
    });
  };

  onChangeText(field, e) {
    let error = this.state.error;
    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  validateForm() {
    let error = this.state.error;

    const { username, password } = this.state;

    if (username === "") {
      error.username = "Username field is required";
    }

    if (password === "") {
      error.password = "Password field is required";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();

    await this.validateForm();

    const error = this.state.error;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      const { username, password } = this.state;

      const options = {
        method: "POST",
        url: AUTH_API + "/login",
        data: {
          email: username,
          password,
        },
      };

      axios(options)
        .then((data) => {
          //SUCCESS
          const { token } = data.data;
          this.handleSuccess(token);

        })
        .catch((err) => {
          this.setState({
            isSubmitting: false,
          });

          if(!err.response){
            return toastMessage(
              "error",
              "Connection failed. This could either be due to network failure or the resource is currently unavailable"
            )
          }
          toastMessage("error", err.response.data.message)
        });
    }
  };

  handleSuccess = async (token) => {
    const { username } = this.state;

    await setStorage({
      username,
      token,
    });

    this.setState({
      isSubmitting: false,
    });

    const userInfo = await this.getUserInfoFromToken(token);
    const userProfile = await this.getAccount(token, userInfo.sub)

    const profile = {
      ...userInfo,
      ...userProfile.data,
      token,
      session_expired: new Date().getTime() / 1000,
    };

    await setStorage(profile);

    window.location.href = "/home";

  };

  getUserInfoFromToken = async (token) => {
    const decoded = decode(token);

    return decoded;
  };

  getAccount = async (token, id) => {
    const options = {
      method: "GET",
      url: AUTH_API + "/user/"+id,
      headers: {
        authorization: "Bearer " + token,
      },
    };

    return await axios(options);
  };

  onHelpLogin() {
    // window.location.href = "/forgot-password";
  }

  showUsername = async () => {
    await clearStorage();

    this.setState({
      username: "",
    });
  };

  render() {
    const isExpired =
      this.state.user &&
      !this.state.user.token &&
      this.state.user.username &&
      this.state.user.username !== "";

    return (
      <div className="form-container login-container">
        <div className="col-md-5 col-xl-3">
          <div className="card">
            <div className="row justify-content-center align-items-center">

              <div className="col-md-12">
                <div className="form-header">
                  <h1>PartsFinder Club</h1>
                  <Logo containerWidth={100} />
                </div>
                <div className="card-body">
                  <form className="needs-validation" novalidate>
                    {isExpired && (
                      <div className="user-session-out alert alert-secondary">
                        <div className="out-user-photo"></div>
                        <div className="out-user-info">
                          <small className="text-danger">
                            <b>SESSION TIMEOUT</b>
                          </small>
                          <p>{this.state.user.username}</p>
                        </div>
                      </div>
                    )}
                    <Input
                      label="Username:"
                      value={this.state.username}
                      onChange={(e) => this.onChangeText("username", e)}
                      placeholder="Email"
                      error={this.state.error.username}
                      required
                      borderBottom
                    />
                    <Input
                      label="Password:"
                      value={this.state.password}
                      type="password"
                      placeholder="******"
                      error={this.state.error.password}
                      required
                      onChange={(e) => this.onChangeText("password", e)}
                      borderBottom
                      showRightContent
                    />
                    <div className="row">
                      <div className="col-md-7">
                        <Button
                          onPress={this.onHelpLogin.bind(this)}
                          className=" btn-secondary w-100"
                          text="Can't login?"
                          path={"/reset-password"}
                          disabled={true}
                          type="link"
                        />
                      </div>
                      <div className="col-md-5">
                        <Button
                          isSubmitting={this.state.isSubmitting}
                          onPress={this.onSubmit.bind(this)}
                          className=" btn-primary w-100"
                          text="Login"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
