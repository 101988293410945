import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import "./App.css"
import "react-toastify/dist/ReactToastify.css"
import {ToastContainer} from "react-toastify"
import AppContext from "./app/context/AppContext"
import {useState} from "react"

//Screens
import SplashPage from "./app/pages/SplashPage"
import LoginPage from "./app/pages/LoginPage"
import HomePage from "./app/pages/HomePage"
import ProductsPage from "./app/pages/ProductsPage"
import ViewUploadsPage from "./app/features/Product/ViewUploads"
import SalesPage from "./app/pages/SalesPage"
import ClientsPage from "./app/pages/ClientsPage"
import UsersPage from "./app/pages/UsersPage"
import SettingsPage from "./app/pages/SettingsPage"
import VehiclesPage from "./app/pages/VehiclesPage"
import MachinesPage from "./app/pages/MachinesPage"
import PartsStockPage from "./app/pages/PartsStockPage";
import ResetPasswordPage from "./app/pages/ResetPassword"
import CreatePasswordPage from "./app/pages/CreatePassword";
import BannerPage from "./app/pages/BannerPage";
import BrandsPage from "./app/pages/BrandsPage";
import LandingPage from "./app/pages/LandingPage";

const createBrowserHistory = require("history").createBrowserHistory

function App() {
    const [uploads, setUploads] = useState([])
    const [menus, setMenus] = useState([])
    const [permissions, setPermissions] = useState([])

    return (
        <div className="App">
            <AppContext.Provider
                value={{
                    uploads, setUploads,
                    menus, setMenus,
                    permissions, setPermissions
                }}
            >
                <Router history={createBrowserHistory({queryKey: false})}>
                    <Switch>
                        <Route exact path="/" component={SplashPage}/>
                        <Route exact path="/login" component={LoginPage}/>
                        <Route exact path="/reset-password" component={ResetPasswordPage}/>
                        <Route exact path="/create-password" component={CreatePasswordPage}/>
                        <Route
                            path="/home"
                            render={(match) => {
                                const pathname = match.location ? match.location.pathname : ""
                                return (
                                    <HomePage location={match.location} match={match}>
                                        <Route
                                            exact
                                            path="/home"
                                            component={LandingPage}
                                            active={pathname.includes("home")}
                                        />
                                        <Route
                                            exact
                                            path="/home/clients"
                                            component={ClientsPage}
                                            active={pathname.includes("clients")}
                                        />
                                        <Route
                                            exact
                                            path="/home/vehicles"
                                            component={VehiclesPage}
                                            active={pathname.includes("vehicles")}
                                        />
                                        <Route
                                            exact
                                            path="/home/machinery"
                                            component={MachinesPage}
                                            active={pathname.includes("machinery")}
                                        />

                                        <Route
                                            exact
                                            path="/home/products"
                                            component={ProductsPage}
                                            active={pathname.includes("products")}
                                        />
                                        <Route
                                            exact
                                            path="/home/products/upload"
                                            component={ViewUploadsPage}
                                            active={pathname.includes("products")}
                                        />
                                        <Route
                                            exact
                                            path="/home/stock"
                                            component={PartsStockPage}
                                            active={pathname.includes("stock")}
                                        />
                                        <Route
                                            exact
                                            path="/home/sales"
                                            component={SalesPage}
                                            active={pathname.includes("sales")}
                                        />
                                        <Route
                                            exact
                                            path="/home/users"
                                            component={UsersPage}
                                            active={pathname.includes("users")}
                                        />
                                        <Route
                                            exact
                                            path="/home/settings"
                                            component={SettingsPage}
                                            active={pathname.includes("settings")}
                                        />
                                        <Route
                                            exact
                                            path="/home/banner"
                                            component={BannerPage}
                                            active={pathname.includes("banner")}
                                        />
                                        <Route
                                            exact
                                            path="/home/brands"
                                            component={BrandsPage}
                                            active={pathname.includes("brands")}
                                        />
                                    </HomePage>
                                )
                            }}
                        />
                    </Switch>
                </Router>
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar={true}
                    closeOnClick={true}
                    pauseOnHover={true}
                    draggable={true}
                    progress={undefined}
                />
            </AppContext.Provider>
        </div>
    )
}

export default App
