import React from 'react'
import { useState } from 'react'
import { Tabs } from '../../components/Tabs'
import Vehicle from './Vehicle'
import Battery from '../Details/Battery'
import Brakes from '../Details/Brakes'
import Bulbs from '../Details/Bulbs'
import Filters from '../Details/Filters'
import Lubricants from '../Details/Lubricants'
import SchockAbsorber from '../Details/SchockAbsorber'
import SparkPlugs from '../Details/SparkPlugs'
import Wipers from '../Details/Wipers'

const VehicleDetails = (props) => {
  const { selectedVehicle, handleClose } = props

  const [options, setOptions] = useState(
    [ 
      {
        title: "Details",
        data: renderVehicleDetails()
      },
      {
        title: "Filters",
        data: renderFilters()
      },
      {
        title: "Lubricants",
        data: renderLubricants()
      },
      {
        title: "Brakes",
        data: renderBrakes()
      },
      {
        title: "Spark Plugs",
        data: renderSparkPlugs()
      },
      {
        title: "Schock Absorber",
        data: renderSchockAbsorber()
      },
      {
        title: "Bulbs",
        data: renderBulbs()
      },
      {
        title: "Wipers",
        data: renderWipers()
      },
      {
        title: "Battery",
        data: renderBattery()
      },
    ]
  )
  const [active, setActive] = useState(null)



  async function changeActiveTab (tab){
    await setActive(tab)
    setActive(null)
  }

  function renderVehicleDetails(){
    return (
      <Vehicle 
        disabled
        changeTab={changeActiveTab}
        selectedVehicle={selectedVehicle}
        // setEditVehicle={setEditVehicle}
        handleClose={handleClose}
      />
    )
  }

  function renderFilters(){
    return (
      <Filters 
        changeTab={changeActiveTab}
        selectedVehicle={selectedVehicle}
        handleClose={handleClose}
      />
    )
  }

  function renderLubricants(){
    return (
      <Lubricants 
        changeTab={changeActiveTab}
        selectedVehicle={selectedVehicle}
        handleClose={handleClose}
      />
    )
  }

  function renderBrakes(){
    return (
      <Brakes 
        changeTab={changeActiveTab}
        selectedVehicle={selectedVehicle}
        handleClose={handleClose}
      />
    )
  }

  function renderSparkPlugs(){
    return (
      <SparkPlugs 
        changeTab={changeActiveTab}
        selectedVehicle={selectedVehicle}
        handleClose={handleClose}
      />
    )
  }

  function renderSchockAbsorber(){
    return (
      <SchockAbsorber 
        changeTab={changeActiveTab}
        selectedVehicle={selectedVehicle}
        handleClose={handleClose}
      />
    )
  }

  function renderBulbs(){
    return (
      <Bulbs 
        changeTab={changeActiveTab}
        selectedVehicle={selectedVehicle}
        handleClose={handleClose}
      />
    )
  }

  function renderWipers(){
    return (
      <Wipers 
        changeTab={changeActiveTab}
        selectedVehicle={selectedVehicle}
        handleClose={handleClose}
      />
    )
  }

  function renderBattery(){
    return (
      <Battery 
        changeTab={changeActiveTab}
        selectedVehicle={selectedVehicle}
        handleClose={handleClose}
      />
    )
  }


  
  return (
    <div>
    <Tabs key={options.title} options={options} activeTab={active} />
  </div>
  )
}

export default VehicleDetails