import React from "react";
import { Spinner } from "react-bootstrap";
import "./style.css";

const LoadingSpinner = (props) => {
  return (
    <div className={`spin-container ${props.className}`}>
      <Spinner variant="primary" aria-hidden="true" animation="border" />
    </div>
  );
};

export default LoadingSpinner;
