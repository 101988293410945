import { useState } from "react"
import {ControlledTabs, Tabs} from "../components/Tabs"
import { ProductsPage } from "../features/Product"

const Products = () => {
	const [active, setActive] = useState("All Stock")

	const renderAllStock = ()=>{
		return <ProductsPage />
	}

	const renderInstock = () => {
		return <ProductsPage type="in" />
	}
	const renderOutOfstock = () => {
		return <ProductsPage type="out" />
	}

	const options = [
		{
			title: "All Stock",
			data: renderAllStock(),
		},
		{
			title: "Available Stock",
			data: renderInstock(),
		},
		{
			title: "Out of Stock",
			data: renderOutOfstock(),
		},
	]

	return(
		<ControlledTabs
			options={options}
			activeKey={active}
			onSelect={key => {
				setActive(key)
			}}
		/>
	)
	// return <Tabs options={options} active={active} />
}

export default Products
