import React, { useState } from 'react'
import { Tabs } from '../../components/Tabs'
import Machinery from './Machinery'
import Battery from '../Details/Battery'
import Filters from '../Details/Filters'
import Lubricants from '../Details/Lubricants'
import Pipes from "../Details/Pipes"

const MachineryDetails = (props) => {
  const { selectedMachinery, handleClose } = props

  const [options, setOptions] = useState(
    [ 
      {
        title: "Details",
        data: renderVehicleDetails()
      },
      {
        title: "Filters",
        data: renderFilters()
      },
      {
        title: "Lubricants",
        data: renderLubricants()
      },
      {
        title: "Battery",
        data: renderBattery()
      },
      {
        title: "Pipes",
        data: renderPipes()
      },
    ]
  )
  const [active, setActive] = useState(null)



  async function changeActiveTab (tab){
    await setActive(tab)
    setActive(null)
  }

  function renderVehicleDetails(){
    return (
      <Machinery 
        disabled
        changeTab={changeActiveTab}
        selectedMachinery={selectedMachinery}
        handleClose={handleClose}
      />
    )
  }

  function renderFilters(){
    return (
      <Filters 
        changeTab={changeActiveTab}
        selectedVehicle={selectedMachinery}
        handleClose={handleClose}
      />
    )
  }

  function renderLubricants(){
    return (
      <Lubricants 
        changeTab={changeActiveTab}
        selectedVehicle={selectedMachinery}
        handleClose={handleClose}
      />
    )
  }

  function renderBattery(){
    return (
      <Battery 
        changeTab={changeActiveTab}
        selectedVehicle={selectedMachinery}
        handleClose={handleClose}
      />
    )
  }

  function renderPipes(){
    return (
      <Pipes 
        changeTab={changeActiveTab}
        selectedVehicle={selectedMachinery}
        handleClose={handleClose}
      />
    )
  }

  return (
    <div>
      <Tabs key={options.title} options={options} activeTab={active} />
    </div>
  )
}

export default MachineryDetails